import { useState, useEffect, lazy, Suspense, } from 'react'
import { useApi, } from 'react-rest-api'
import {
  BrowserRouter,
  Redirect,
} from 'react-router-dom'

import { useApp, APP_STATUS, } from '@velogik/component-app'
import { ConfirmTransition, } from '@velogik/component-confirm-transition'
import { ErrorRoute, } from '@velogik/component-error-route'
import { useT, } from '@velogik/component-intl'
import { Route, } from '@velogik/component-route'
import { SwitchProvider, } from '@velogik/component-switch'

import { Analytics, } from 'Analytics'
import { RouteEvents, } from 'RouteEvents'

import { Header, } from 'Header'
import { Footer, } from 'Footer'
import { Loading, } from 'pages/Loading'

import { useAnalytics, } from 'components/Analytics'

// import { reportWebVitals } from 'reportWebVitals'

const NotFound = lazy(() => import('pages/NotFound'))
const SignIn = lazy(() => import('pages/SignIn'))
const SignUp = lazy(() => import('pages/SignUp'))
const ForgotPassword = lazy(() => import('pages/ForgotPassword'))
const NewPassword = lazy(() => import('pages/NewPassword'))
const Home = lazy(() => import('pages/Home'))
const FAQ = lazy(() => import('pages/FAQ'))
const HowItWorks = lazy(() => import('pages/HowItWorks'))
const Pro = lazy(() => import('pages/Pro'))
const Backoffice = lazy(() => import('pages/Backoffice'))
const Services = lazy(() => import('pages/Services'))
const VLA = lazy(() => import('pages/VLA'))
const Account = {
  View: lazy(() => import('pages/Account')),
  Details: lazy(() => import('pages/Account.Details')),
  Password: lazy(() => import('pages/Account.Password')),
  Billing: lazy(() => import('pages/Account.Billing')),
  Notifications: lazy(() => import('pages/Account.Notifications')),
}
const SearchShops = lazy(() => import('pages/SearchShops'))
const Equipment = {
  List: lazy(() => import('pages/Equipments')),
  Details: lazy(() => import('pages/EquipmentDetails')),
  View: lazy(() => import('pages/EquipmentView')),
}
const Intervention = {
  List: lazy(() => import('pages/InterventionList')),
  View: lazy(() => import('pages/InterventionView')),
  Rate: lazy(() => import('pages/InterventionRate')),
}
const Shop = {
  Details: lazy(() => import('pages/Shop')),
  Contact: lazy(() => import('pages/ShopContact')),
}
const Contact = lazy(() => import('pages/Contact'))
const Book = lazy(() => import('pages/Book'))
const Legal =  lazy(() => import('pages/Legal'))

const windowTitle = document.querySelector('title')
const metaDescription = document.querySelector('meta[name="description"]')

export function Main() {
  const t = useT()
  const api = useApi()
  const { appState, appDispatch, } = useApp()
  const [confirmCallback, setConfirmCallback] = useState()
  const analytics = useAnalytics()

  useEffect(() => {
    function getUserInfo () {
      return api.config.headers.Authorization
        ? api.get('/users/me').catch(() => Promise.resolve())
        : Promise.resolve()
    }

    Promise.all([
      getUserInfo(),
      api.get('/root/constants'),
    ])
      .then(([user, constants]) => {
        analytics.insertGTM(constants.GTM || process.env.REACT_APP_GTM_ID)
        // reportWebVitals(analytics.webVitals)

        const root = document.body
        Object.entries(constants.styles).forEach(([key, value]) => {
          root.style.setProperty(key, value)
        })

        appDispatch({ type: 'ready', payload: { user, constants }})
      })
      .catch(payload => appDispatch({ type: 'error', payload }))
  }, [api, analytics, appDispatch,])

  function getConfirmation(message, callback) {
    setConfirmCallback({
      to: message,
      callback: (allowTransition) => {
        callback(allowTransition)
        setConfirmCallback()
      }
    })
  }

  function sendAlert (body) {
    return process.env.REACT_APP_DISABLE_SEND_REPORT
      ? Promise.resolve().then(() => console.log(body.err))
      : api.post('/velocenter/alert', {
        body: JSON.stringify(body)
      })
  }

  function formatError (location, error, errorInfo) {
    function formatState (type) {
      switch (type) {
      // TODO: filter props to display once defined
        case 'report':
        default: return JSON.stringify(appState, null, 2)
      }
    }

    return `
Page path: ${window.location.href}
User Agent: ${navigator.userAgent}
AppState:
\`\`\`${formatState('report')}\`\`\`
Location:
\`\`\`${JSON.stringify(location, null, 2)}\`\`\`
Trace:
> ${error.toString().split('\n').join('\n> ')}
\`\`\`${errorInfo.componentStack}\`\`\``
  }

  function guardian ({ match, }) {
    const path = match.path.split('/').filter(_ => _).join('_').replace(':', '')
    if (windowTitle) {
      windowTitle.text = t({ id: 'window.title' }, { path })
    }
    if (metaDescription) {
      metaDescription.setAttribute('content', t({ id: 'meta.description' }, { path }))
    }

    switch(match.path) {
      case '/account':
      case '/account/details':
      case '/account/password':
      case '/account/billing':
      case '/account/notifications':
      case '/shops/:shopId/contact':
      case '/equipments':
      case '/equipments/new':
      case '/equipments/:equipmentId':
      case '/equipments/:equipmentId/edit':
      case '/interventions':
      case '/interventions/:interventionId':
      case '/interventions/:interventionId/rate':
        if (!appState.user) {
          return { pathname: '/sign-in' }
        }
        break
      default:
    }
  }

  return (
    <BrowserRouter getUserConfirmation={getConfirmation}>
      <Suspense fallback={<Loading />}>
        <Analytics />
        <RouteEvents />
        {appState.status === APP_STATUS.LOADING && <Loading />}
        {appState.status === APP_STATUS.LOADED && <Header />}
        {appState.status === APP_STATUS.LOADED && (
          <SwitchProvider
            alert={sendAlert}
            formatError={formatError}
            guardian={guardian}
            fallbackEmail={process.env.REACT_APP_FALLBACK_EMAIL}
          >
            <Route path="/sign-in"><SignIn /></Route>
            <Route path="/sign-up"><SignUp /></Route>
            <Route path="/forgot-password"><ForgotPassword /></Route>
            <Route path="/new-password"><NewPassword /></Route>
            <Route path="/contact"><Contact /></Route>
            {appState.brandSlug && <Redirect exact path="/" to="/search-shops" />}
            {!appState.brandSlug && <Route exact path="/"><Home /></Route>}
            <Route exact path="/faq"><FAQ /></Route>
            <Route exact path="/how-it-works"><HowItWorks /></Route>
            <Route exact path="/pro"><Pro /></Route>
            <Route exact path="/backoffice"><Backoffice /></Route>
            <Route exact path="/services"><Services /></Route>
            <Route exact path="/vla"><VLA /></Route>
            <Route exact path="/account"><Account.View /></Route>
            <Route exact path="/account/details"><Account.Details /></Route>
            <Route exact path="/account/password"><Account.Password /></Route>
            <Route exact path="/account/billing"><Account.Billing /></Route>
            <Route exact path="/account/notifications"><Account.Notifications /></Route>
            <Route path="/search-shops"><SearchShops /></Route>
            <Route exact path="/shops/:shopId"><Shop.Details /></Route>
            <Route exact path="/shops/:shopId/contact"><Shop.Contact /></Route>
            <Route exact path="/equipments"><Equipment.List /></Route>
            <Route exact path="/equipments/new"><Equipment.Details redirectOnCreate={equipment => `/equipments/${equipment.equipmentId}`} /></Route>
            <Route exact path="/equipments/:equipmentId"><Equipment.View /></Route>
            <Route exact path="/equipments/:equipmentId/edit"><Equipment.Details /></Route>
            <Route exact path="/interventions"><Intervention.List /></Route>
            <Route exact path="/interventions/:interventionId"><Intervention.View /></Route>
            <Route exact path="/interventions/:interventionId/rate"><Intervention.Rate /></Route>
            <Route path="/book"><Book /></Route>
            <Route path="/legal/terms"><Legal /></Route>
            <Route path="/legal/privacy"><Legal /></Route>
            <Route path="/legal/mentions"><Legal /></Route>
            <Route path="*"><NotFound /></Route>
          </SwitchProvider>
        )}
        {appState.status === APP_STATUS.LOADED && <Footer />}
        {appState.status === APP_STATUS.ERROR && (
          <ErrorRoute state={{ payload: appState.error }} />
        )}
        <ConfirmTransition confirmCallback={confirmCallback} />
      </Suspense>
    </BrowserRouter>
  )
}
