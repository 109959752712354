import { useApp, } from '@velogik/component-app'
import { useT, } from '@velogik/component-intl'
import { Link, Url, } from '@velogik/component-link'
import { join, } from '@velogik/helper-classnames'

import style from './Footer.module.scss'
import logo from './assets/logo-full-white.svg'

export function Footer () {
  const { appState, } = useApp()
  const t = useT('Footer', true)

  return (
    <nav className={style.root}>
      <div className={style.category}>
        <div className={style.title}>{t({ id: 'legal.title' })}</div>
        <Link className={style.link} to="/legal/terms">{t({ id: 'legal.terms' })}</Link>
        <Link className={style.link} to="/legal/privacy">{t({ id: 'legal.privacy' })}</Link>
        <Link className={style.link} to="/legal/mentions">{t({ id: 'legal.mentions' })}</Link>
      </div>
      {!appState.brandSlug && <div className={style.category}>
        <div className={style.title}>{t({ id: 'commercial.title' })}</div>
        <Link className={style.link} to="/pro">{t({ id: 'commercial.subscription' })}</Link>
        <Link className={style.link} to="/faq">{t({ id: 'commercial.faq' })}</Link>
        <Link className={style.link} to="/how-it-works">{t({ id: 'commercial.howItWorks' })}</Link>
        <Link className={style.link} to="/backoffice">{t({ id: 'commercial.backoffice' })}</Link>
        <Link className={style.link} to="/services">{t({ id: 'commercial.offers' })}</Link>
        <Link className={style.link} to="/vla">{t({ id: 'commercial.vla' })}</Link>
      </div>}
      {!appState.brandSlug && <div className={style.category}>
        <div className={style.title}>{t({ id: 'social.title' })}</div>
        <Url className={join(style.link, style.instagram)} href="/instagram">{t({ id: 'social.instagram' })}</Url>
        <Url className={join(style.link, style.facebook)} href="/facebook">{t({ id: 'social.facebook' })}</Url>
        <Url className={join(style.link, style.twitter)} href="/twitter">{t({ id: 'social.twitter' })}</Url>
      </div>}
      <div className={style.category}>
        <img className={style.logo} src={logo} alt="" />
        <div className={style.version}>{t({ id: 'version' }, { version: process.env.REACT_APP_VERSION })}</div>
      </div>
    </nav>
  )
}
