import { shouldPolyfill } from '@formatjs/intl-relativetimeformat/should-polyfill'

export default async function polyfill(locale) {
  if (!shouldPolyfill()) {
    return
  }

  await import('@formatjs/intl-relativetimeformat/polyfill')

  switch (locale) {
    default:
      await import('@formatjs/intl-relativetimeformat/locale-data/en')
      break
    case 'fr':
      await import('@formatjs/intl-relativetimeformat/locale-data/fr')
      break
  }
}
