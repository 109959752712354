import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill'

export default async function polyfill(locale) {
  if (!shouldPolyfill()) {
    return
  }

  await import('@formatjs/intl-datetimeformat/polyfill')

  // Parallelize CLDR data loading
  const dataPolyfills = [import('@formatjs/intl-datetimeformat/add-all-tz')]

  switch (locale) {
    default:
      dataPolyfills.push(import('@formatjs/intl-datetimeformat/locale-data/en'))
      break
    case 'fr':
      dataPolyfills.push(import('@formatjs/intl-datetimeformat/locale-data/fr'))
      break
  }
  await Promise.all(dataPolyfills)
}
