import getCanonicalLocales from './getCanonicalLocales'
import Locale from './Locale'
import PluralRules from './PluralRules'
import DisplayNames from './DisplayNames'
import NumberFormat from './NumberFormat'
import DateTimeFormat from './DateTimeFormat'
import RelativeTimeFormat from './RelativeTimeFormat'

export const Intl = [
  getCanonicalLocales,
  Locale,
  PluralRules,
  DisplayNames,
  NumberFormat,
  DateTimeFormat,
  RelativeTimeFormat,
]

export default Intl
