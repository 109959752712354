import { useT, } from '@velogik/component-intl'

import style from './Loading.module.scss'

export function Loading (props) {
  const t = useT()

  return (
    <div className={style.root} {...props}>
      {t({ id: 'loading' })}
    </div>
  )
}
