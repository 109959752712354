import React from 'react'
import ReactDOM from 'react-dom'

import polyfills from './polyfills'

import { ErrorBoundary, FETCH_STATUS, } from '@velogik/component-error-boundary'
import { ErrorGlobal, } from '@velogik/component-error-global'

import '@velogik/component-address-input/dist/index.css'
import '@velogik/component-alert/dist/index.css'
import '@velogik/component-banner/dist/index.css'
import '@velogik/component-block/dist/index.css'
import '@velogik/component-button/dist/index.css'
import '@velogik/component-confirm-transition/dist/index.css'
import '@velogik/component-form/dist/index.css'
import '@velogik/component-heading/dist/index.css'
import '@velogik/component-input/dist/index.css'
import '@velogik/component-label/dist/index.css'
import '@velogik/component-link/dist/index.css'
import '@velogik/component-loading/dist/index.css'
import '@velogik/component-notifications/dist/index.css'
import '@velogik/component-page/dist/index.css'
import '@velogik/component-phone-input/dist/index.css'
import '@velogik/component-result-infos/dist/index.css'
import '@velogik/component-result-pagination/dist/index.css'
import '@velogik/component-select-input/dist/index.css'

import { AnalyticsProvider, } from 'components/Analytics'

import './fonts'
import './index.css'

function render () {
  const { App } = require('App')

  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary
        render={ErrorGlobal}
        alert={sendAlert}
        parseError={parseError}
        fallbackEmail={process.env.REACT_APP_FALLBACK_EMAIL}
      >
        <AnalyticsProvider>
          <App />
        </AnalyticsProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

Promise.all(
  polyfills.Intl.map(polyfill => polyfill('fr')),
)
  .then(render)
  .catch(error => {
    console.log(error)

    ReactDOM.render(
      <ErrorGlobal
        state={{ status: FETCH_STATUS.ERROR, payload: error.toString() }}
        alert={sendAlert}
        fallbackEmail={process.env.REACT_APP_FALLBACK_EMAIL} />
      ,
      document.getElementById('root')
    )
  })

function sendAlert (body) {
  return process.env.REACT_APP_DISABLE_SEND_REPORT
    ? Promise.resolve().then(() => console.log(body.err))
    : fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/velocenter/alert`, {
      method: 'POST',
      headers: {
        'X-Velocenter-Apikey': process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(body)
    }).then(response => (response.ok ? response.json() : response.json().then(res => Promise.reject(res)))
      .then(response => response && response.code ? Promise.reject(response) : Promise.resolve(response)))
      .catch(response => Promise.reject(response || { code: 'unexpected' }))
}

function parseError (error, errorInfo) {
  return (
    `:warning: *Fatal Error !!* :warning:
Page path: ${window.location.href}
User Agent: ${navigator.userAgent}
> ${error.toString().split('\n').join('\n> ')}
\`\`\`${errorInfo.componentStack}\`\`\``
  )
}
