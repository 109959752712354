export const messages = { 
  // `window.title` and `meta.description` uses match.path internal `path` for routes transformed to be valid as select key
  // So /vla become vla
  // /intervention/2/rate -> is /intervention/:interventionId/rate become `interventions_interventionId_rate`
  'window.title': `{ path, select,
    vla {Velocenter x Velogik Les Ateliers}
    other {Velocenter - Trouvez le meilleur réparateur pour votre vélo}
  }`,
  'meta.description': `{ path, select,
    vla {Velogik Les Ateliers, c'est le réseau de franchise spécialisé en réparation cycle - made by Velogik}
    other {Velocenter est une plateforme proposant les meilleurs techniciens et ateliers pour votre vélo dans votre ville. Réservez facilement en quelques clics votre rendez-vous à votre domicile ou en atelier.}
  }`,
  'loading': 'Plus que quelques etapes avant la reparation de votre Vélo 💪 ....',
  'Form.dataLoading': 'Chargement...', // TODO
  'ResultInfos.ipp': 'Résultats par page :',
  'ResultInfos.total': `{ total, select, 
    0 {aucun} 
    1 {1 résultat}
    other {{total} résultats} 
  }`,
  'ResultPagination.previous': 'Page précédente',
  'ResultPagination.next': 'Page suivante',

  'Mandatory.full': 'Tous les champs sont obligatoires',
  'Mandatory.partial': 'Les champs marqués d\'un * sont obligatoires',

  '/not-found.title': 'Page introuvable',
  '/not-found.content': 'Désolé, la page que vous recherchez n\'existe pas. Elle a peut-être été déplacée ou supprimée. Ou alors elle n\'a jamais existé !',
  '/not-found.button': 'Retour à l\'accueil',

  '/legal/mentions.pageTitle': 'Mentions légales',
  '/legal/terms.pageTitle': 'Conditions Générales d\'Utilisation',
  '/legal/privacy.pageTitle': 'Politique de confidentialité',

  'InterventionBanner.disclaimer': 'Vous avez une réservation non finalisée',
  'InterventionBanner.go': 'Ma réservation en cours',

  '/sign-up.legalNotice': 'Oui j\'approuve les <legalTermsLink>CGU</legalTermsLink> et la <legalPrivacyLink>politique de confidentialité</legalPrivacyLink>',

  '/account.pageTitle': 'Bienvenue sur votre compte !',
  '/account.blockTitle': 'Mes informations',
  '/account.email.label': 'Mes informations personnelles',
  '/account.password.label': 'Mon mot de passe',
  '/account.billingAddress.label': 'Mon adresse de facturation',
  '/account.notifications.label': 'Notifications',

  '/account/details.pageTitle': 'Votre profil a changé ?',
  '/account/details.form.title': 'Je modifie mes informations',
  '/account/details.form.firstName.label': 'Mon prénom *',
  '/account/details.form.firstName.placeholder': ' ',
  '/account/details.form.lastName.label': 'Mon nom *',
  '/account/details.form.lastName.placeholder': ' ',
  '/account/details.form.email.label': 'Mon email *',
  '/account/details.form.email.placeholder': 'votre@email.com',
  '/account/details.form.phoneNumber.label': 'Mon téléphone',
  '/account/details.form.phoneNumber.placeholder': '+33',
  '/account/details.form.phoneNumber.validation.valid': 'Téléphone verifié',
  '/account/details.form.phoneNumber.validation.invalid': 'J\'accepte de recevoir les communications par SMS',
  '/account/details.form.phoneNumber.validation.sendCode': 'Je vérifie',
  '/account/details.form.phoneNumber.validation.codesent.label': 'Code de confirmation recu par SMS',
  '/account/details.form.phoneNumber.validation.codesent.placeholder': '123456',
  '/account/details.form.phoneNumber.validation.resend': 'Renvoyer un nouveau code',
  '/account/details.form.phoneNumber.validation.confirm': 'Je confirme',
  '/account/details.form.newsletter.label': 'Je veux recevoir l\'actu de Vélocenter',
  '/account/details.form.actions.submit': 'Je modifie',
  '/account/details.success': 'Votre profil a bien été modifié !',

  '/account/password.pageTitle': 'Vous voulez changer votre mot de passe ?',
  '/account/password.form.title': 'Je modifie mon mot de passe',
  '/account/password.form.currentPassword.label': 'Mon mot de passe actuel',
  '/account/password.form.currentPassword.placeholder': ' ',
  '/account/password.form.newPassword.label': 'Mon nouveau mot de passe',
  '/account/password.form.newPassword.placeholder': ' ',
  '/account/password.form.confirmPassword.label': 'Ma confirmation du nouveau mot de passe',
  '/account/password.form.confirmPassword.placeholder': ' ',
  '/account/password.form.actions.submit': 'Je modifie',
  '/account/password.success': 'Votre mot de passe a bien été modifié !',

  '/account/billing.pageTitle': 'Votre adresse de facturation a changé ?',
  '/account/billing.form.title': 'Je modifie mon adresse de facturation',
  '/account/billing.form.billingAddress.label': 'Mon adresse',
  '/account/billing.form.billingAddress.placeholder': 'Adresse complète...',
  '/account/billing.form.actions.submit': 'Je modifie',
  '/account/billing.success': 'Votre adresse de facturation a bien été changé !',

  '/account/notifications.pageTitle': 'Notifications',
  '/account/notifications.form.main': 'J\'accepte de recevoir les notifications suivantes par sms :',
  '/account/notifications.form.interventionDone': 'L\'intervention sur mon vélo est terminée',
  '/account/notifications.form.estimateSent': 'Mon devis est disponible au téléchargement',
  '/account/notifications.form.datePlannedUpdated': 'Mon rendez-vous avec le technicien est modifié',
  '/account/notifications.form.rdvRemind24': 'Me rappeler 24h avant le rendez-vous',
  '/account/notifications.form.email': 'J\'accepte de recevoir les notifications suivantes par email :',
  '/account/notifications.form.interventionConfirmEmail': 'Confirmation d\'une intervention',
  '/account/notifications.form.interventionReminderEmail': 'Rappel d\'une intervention',
  '/account/notifications.form.interventionDoneEmail': 'Intervention terminée',
  '/account/notifications.form.clientReattachmentEmail': 'Rattachement a un nouvel atelier',
  '/account/notifications.form.equipmentNewOwnershipEmail': 'Changer de proprietaire d\'un equipement',
  '/account/notifications.form.estimateEmail': 'Devis disponible',
  '/account/notifications.form.interventionInvoiceEmail': 'Facture disponible',
  '/account/notifications.form.actions.submit': 'Sauvegarder',
  '/account/notifications.success': 'Modifications sauvegardées',

  // /shops/:shopId
  '/shops/:shopId.Summary.isOpen': `{ isOpen, select,
    true {<green>Ouvert</green> • Ferme à { closeTime }}
    false {<red>Fermé</red>}
    other {Erreur}
  }`,
  '/shops/:shopId.Summary.schedule.day': `{ day, select,
    monday {Lundi}
    tuesday {Mardi}
    wednesday {Mercredi}
    thursday {Jeudi}
    friday {Vendredi}
    saturday {Samedi}
    sunday {Dimanche}
    other {{ day }}
  }`,
  '/shops/:shopId.Summary.schedule.times': '{ open } - { close }',
  '/shops/:shopId.Availabilities.Calendar.day': '<title><capitalize>{ formattedDay }</capitalize></title><subTitle>{ date, date, ::dMMM }</subTitle>',
  '/shops/:shopId.Availabilities.Calendar.slot': `{ type, select,
    empty {-}
    other {{ date, time, short }}
  }`,
  '/shops/:shopId.Availabilities.Calendar.dayEmpty': 'Plus aucun créneau',
  '/shops/:shopId.Availabilities.Calendar.previous': 'Semaine précedente',
  '/shops/:shopId.Availabilities.Calendar.next': 'Semaine suivante',

  '/book/missing-informations.repairLocation.label.shop': 'En atelier',
  '/book/missing-informations.repairLocation.label.home': 'À mon domicile',
  '/book/missing-informations.equipmentType.label.vae': 'Vélo à Assistance Électrique',
  '/book/missing-informations.equipmentType.label.standard': 'Vélo standard',
  '/book/missing-informations.equipmentType.label.cargo': 'Vélo cargo',
  '/book/missing-informations.equipmentType.label.cargoae': 'Vélo cargo électrique',
  '/book/missing-informations.equipmentType.label.other': 'Autre',

  '/book/missing-shop.pageTitle': 'Aucun atelier sélectionné',
  '/book/missing-shop.content': 'Veuillez lancer une nouvelle recherche',
  '/book/missing-shop.search-shops': 'Nouvelle recherche',

  '/book/self-diagnosis.repairServicesDetails.placeholder': 'Choisir un type de requete a l\'etape precedente',
  '/book/self-diagnosis.repairServicesDetails.noResult': 'Il n\'y pas de categorie pour cette requete',

  '/book/self-diagnosis.pageTitle': 'Parlez-nous de votre vélo',
  '/book/self-diagnosis.repairServices.title': 'Une idée de l\'intervention ?',
  '/book/self-diagnosis.repairServices.label.breakdown': 'Réparer mon vélo',
  '/book/self-diagnosis.repairServices.label.overhaul': 'Faire réviser ou mettre en service',
  '/book/self-diagnosis.repairServices.label.spare': 'Monter une pièce détachée',
  '/book/self-diagnosis.repairServices.label.other': 'Autre besoin',
  '/book/self-diagnosis.next': 'Je passe à l\'étape suivante',
  '/book/self-diagnosis.rectify': 'Étape précédente',
  '/book/self-diagnosis.repairServicesDetails.title': 'Des précisions ?',
  '/book/self-diagnosis.repairServicesDetails.label.speed': 'Mes vitesses ne passent plus',
  '/book/self-diagnosis.repairServicesDetails.label.crankset': 'Mon pédalier est défectueux ',
  '/book/self-diagnosis.repairServicesDetails.label.flatTire': 'Mon vélo a un pneu à plat',
  '/book/self-diagnosis.repairServicesDetails.label.brokenWheel': 'Ma roue est cassée',
  '/book/self-diagnosis.repairServicesDetails.label.brakeAdjustment': 'Mes freins fonctionnent mal',
  '/book/self-diagnosis.repairServicesDetails.label.brokenBrakes': 'Mes freins sont cassés',
  '/book/self-diagnosis.repairServicesDetails.label.battery': 'Ma batterie a des soucis',
  '/book/self-diagnosis.repairServicesDetails.label.diag': 'RDV pour diagnostiquer ma panne',
  '/book/self-diagnosis.repairServicesDetails.label.overhaul': 'Je souhaite faire réviser mon vélo',
  '/book/self-diagnosis.repairServicesDetails.label.setup': 'Je souhaite mettre en service un vélo neuf',
  '/book/self-diagnosis.repairServicesDetails.label.spareMine': 'Je souhaite faire installer une pièce détachée en ma possession',
  '/book/self-diagnosis.repairServicesDetails.label.spareBuy': 'Je souhaite faire installer une pièce détachée avec achat',
  '/book/self-diagnosis.repairServicesDetails.label.kit': 'Je souhaite faire installer un kit électrique',
  '/book/self-diagnosis.repairServicesDetails.label.accessory': 'Je souhaite faire installer un petit accessoire',
  '/book/self-diagnosis.repairServicesDetails.label.idSetup': 'Je souhaite faire poser une identification',
  '/book/self-diagnosis.repairServicesDetails.label.lockDisassembly': 'Je souhaite faire démonter un antivol',
  '/book/self-diagnosis.repairServicesDetails.label.spareDisassembly': 'Je souhaite faire démonter une pièce détachée',
  '/book/self-diagnosis.repairServicesDetails.label.other': 'Autre besoin',
  '/book/self-diagnosis.repairServicesDetails.label.otherBreakdown': 'Autre',
  '/book/self-diagnosis.equipmentType.title': 'Vous nous en dites plus ? ',
  '/book/self-diagnosis.equipmentType.description.label': 'Plus de détails',
  '/book/self-diagnosis.equipmentType.description.placeholder': 'Marque ? Année d\'achat ? Couleur Vélo ? Problème ? Pièce concernée ? ...',
  '/book/self-diagnosis.additional.title': 'Et si vous profitiez de votre rendez-vous pour faire une révision complète de votre vélo ?',
  '/book/self-diagnosis.additional.disclaimer': 'Réservez le créneau dès aujourd’hui. Vous pourrez toujours annuler au dernier moment, si vous changez d’avis.',
  '/book/self-diagnosis.additional.label': 'Je souhaite ajouter une révision de mon vélo',
  '/book/self-diagnosis.additional.pictures.add': 'Ajouter une photo',
  '/book/self-diagnosis.additional.pictures.remove': 'Supprimer une photo',
  '/book/self-diagnosis.dirty.dismiss': 'J\'ai compris',
  '/book/self-diagnosis.error.title': 'Une erreur est survenue',
  '/book/self-diagnosis.retry': 'Je réessaye',
  '/book/self-diagnosis.repairLocationNotAllowedOnline.action': 'Je modifie le type de prestation',

  '/book/estimate.pageTitle': 'Mon devis indicatif',
  '/book/estimate.details.title': 'Pourquoi indicatif ?',
  '/book/estimate.details.disclaimer': 'Parce qu’il peut changer si, sur place, le réparateur revoit ce premier diagnostic. Il validera les changements avec vous avant de se lancer et vous n\'aviez rien à avancer aujourd\'hui. Confiance garantie !',
  '/book/estimate.details.duration.label': 'Durée de l’intervention',
  '/book/estimate.details.prices.label': 'Montant des prestations',
  '/book/estimate.details.duration.value': '{ duration, time, short }',
  '/book/estimate.details.prices.value': '{ itvLabel } { itvPrice, number, ::currency/EUR }',
  '/book/estimate.details.fee.value': `{ travelFees, select,
    0 {Offert}
    other {{ travelFees, number, ::currency/EUR }}
    }`,
  '/book/estimate.details.total.value': '{ totalWt, number, ::currency/EUR }',
  '/book/estimate.details.fee.label': 'Frais de déplacement',
  '/book/estimate.details.total.label': 'Total estimé',
  '/book/estimate.submit': 'Je valide le devis',
  '/book/estimate.summary.title': 'Mon récapitulatif',
  '/book/estimate.summary.repairLocation.label': 'Type de prestation',
  '/book/estimate.summary.address.label': '{ repairLocation, select, home {Mon adresse} other {Adresse de l\'atelier}}',
  '/book/estimate.summary.address.value': '{ addressFull }',
  '/book/estimate.summary.equipmentType.label': 'Mon vélo',
  '/book/estimate.summary.repairServices.label': 'Prestation de service',
  '/book/estimate.summary.repairServicesDetails.label': 'Détail de la prestation',
  '/book/estimate.summary.additional.label': 'Révision complémentaire',
  '/book/estimate.summary.slot.label': 'Date et heure',
  '/book/estimate.summary.repairLocation.value': '{ repairLocation, select, home {À domicile} shop {En atelier} other {Erreur} }',
  '/book/estimate.summary.equipmentType.value': `{ equipmentType, select,
    standard {Vélo standard}
    cargo {Vélo cargo}
    vae {Vélo à Assistance Electrique}
    cargoae {Vélo cargo électrique}
    other { Autre }
  }`,
  '/book/estimate.summary.repairServices.value': `{ repairService, select,
    breakdown {Panne}
    overhaul {Révision ou mise à la route}
    spare {Installation pièce détachée}
    other {Autre}
  }`,
  '/book/estimate.summary.repairServicesDetails.value': `{ repairServiceDetails, select,
    speed {Vitesse}
    crankset {Pédalier}
    flatTire {Pneu à plat}
    brokenWheel {Roue cassée}
    brakeAdjustment {Disfonctionnement des freins}
    brokenBrakes {Freins cassés}
    battery {Batterie}
    diag {Diagnostic}
    overhaul {Révision}
    setup {Mise en service}
    spareMine {Pièce détachée fournie}
    spareBuy {Pièce détachée à acheter}
    kit {Kit de motorisation}
    accessory {Petit accessoire}
    idSetup {Système d'identification}
    lockDisassembly {Démontage antivol}
    spareDisassembly {Démontage pièce détachée}
    other {Autre}
  }`,
  '/book/estimate.summary.additional.value': '{ additional, select, true {oui} false {non} other {Erreur} }',
  '/book/estimate.summary.date.value': '{ date }',
  '/book/estimate.summary.slot.value': '{ slot, date, ::yMdHm }',

  // https://unicode-org.github.io/icu/userguide/format_parse/messages/#date-skeletons
  '/book/plan.calendar.day': '<title><capitalize>{ formattedDay }</capitalize></title><subTitle>{ date, date, ::dMMM }</subTitle>',
  '/book/plan.calendar.slot': `{ type, select,
    empty {-}
    other {{ date, time, short }}
  }`,

  '/book/plan.pageTitle': 'Mon rendez-vous',
  '/book/plan.planning.title': 'Je choisis mon créneau',
  '/book/plan.planning.calendar.previous': 'Semaine précédente',
  '/book/plan.planning.calendar.next': 'Semaine suivante',
  '/book/plan.planning.actions.disclaimer': `{ repairLocation, select,
    shop {{ depositTerms, select,
      null {Rendez-vous à l'atelier pour l'heure prévue du rendez-vous. Le prestataire vous préviendra ensuite quand votre vélo sera prêt à être récupéré.}
      other {{ depositTerms }}
    }}
    other {Le prestataire sera présent à l'adresse renseignée pour l'heure prévue du rendez-vous.}
  }`,
  '/book/plan.dayEmpty': 'Pas de créneau disponible',
  '/book/plan.slotRequired': 'Dès que vous avez choisi votre créneau, vous pouvez passer à la dernière étape !',
  '/book/plan.noSlots': 'Prochaine disponibilité le {firstAvailabilitiy, date}',
  '/book/plan.planning.actions.datePlanned': 'Créneau sélectionné : { datePlanned }',
  '/book/plan.submit': 'Je valide le créneau',
  '/book/plan.summary.title': 'Mon récapitulatif',
  '/book/plan.summary.repairLocation.label': 'Type de prestation',
  '/book/plan.summary.address.label': 'Adresse de l\'intervention',
  '/book/plan.summary.equipmentType.label': 'Mon vélo',
  '/book/plan.summary.repairServices.label': 'Prestation de service',
  '/book/plan.summary.repairServicesDetails.label': 'Détail de la prestation',
  '/book/plan.summary.additional.label': 'Révision complémentaire',
  '/book/plan.summary.duration.label': 'Durée de l\'intervention',

  '/book/plan.summary.repairLocation.value': '{ repairLocation, select, home {À domicile} shop {En atelier} other {Erreur}}',
  '/book/plan.summary.address.value': '{ addressFull }',
  '/book/plan.summary.equipmentType.value': `{ equipmentType, select,
    standard {Vélo standard}
    cargo {Vélo cargo}
    vae {Vélo à Assistance Electrique}
    cargoae {Vélo cargo électrique}
    other { Autre }
  }`,
  '/book/plan.summary.repairServices.value': `{ repairService, select,
    breakdown {Panne}
    overhaul {Révision ou mise à la route}
    spare {Installation pièce détachée}
    other {Autre}
  }`,
  '/book/plan.summary.repairServicesDetails.value': `{ repairServiceDetails, select,
    speed {Vitesse}
    crankset {Pédalier}
    flatTire {Pneu à plat}
    brokenWheel {Roue cassée}
    brakeAdjustment {Disfonctionnement des freins}
    brokenBrakes {Freins cassés}
    battery {Batterie}
    diag {Diagnostic}
    overhaul {Révision}
    setup {Mise en service}
    spareMine {Pièce détachée fournie}
    spareBuy {Pièce détachée à acheter}
    kit {Kit de motorisation}
    accessory {Petit accessoire}
    idSetup {Système d'identification}
    lockDisassembly {Démontage antivol}
    spareDisassembly {Démontage pièce détachée}
    other {Autre}
  }`,
  '/book/plan.summary.additional.value': '{ additional, select, true {oui} false {non} other {Erreur} }',
  '/book/plan.summary.duration.value': '{ duration, time, short }',
  '/book/plan.dismiss': 'J\'ai compris',
  '/book/plan.retry': 'Réessayer',

  '/book/bike-select.equipments.label': `
    <brandTag>{brand}</brandTag>
    <equipmentTypeTag>
      {equipmentType, select,
        standard {Standard}
        vae {Vélo à assistance éléctrique}
        cargo {Vélo cargo}
        cargoae {Vélo cargo éléctrique}
        velocare {Vélo { business }}
        other {{ equipmentType }}
      }
    </equipmentTypeTag>
    <customNameTag>{customName}</customNameTag>
  `,
  '/book/bike-select.summary.repairLocation.value': '{ repairLocation, select, home {À domicile} shop {En atelier} other {Erreur} }',
  '/book/bike-select.summary.address.value': '{ addressFull }',
  '/book/bike-select.summary.equipmentType.value': `{ equipmentType, select,
    standard {Vélo standard}
    cargo {Vélo cargo}
    vae {Vélo à Assistance Electrique}
    cargoae {Vélo cargo électrique}
    other { Autre }
  }`,
  '/book/bike-select.summary.repairServices.value': `{ repairService, select,
    breakdown {Panne}
    overhaul {Révision ou mise à la route}
    spare {Installation pièce détachée}
    other {Autre}
  }`,
  '/book/bike-select.summary.repairServicesDetails.value': `{ repairServiceDetails, select,
    speed {Vitesse}
    crankset {Pédalier}
    flatTire {Pneu à plat}
    brokenWheel {Roue cassée}
    brakeAdjustment {Disfonctionnement des freins}
    brokenBrakes {Freins cassés}
    battery {Batterie}
    diag {Diagnostic}
    overhaul {Révision}
    setup {Mise en service}
    spareMine {Pièce détachée fournie}
    spareBuy {Pièce détachée à acheter}
    kit {Kit de motorisation}
    accessory {Petit accessoire}
    idSetup {Système d'identification}
    lockDisassembly {Démontage antivol}
    spareDisassembly {Démontage pièce détachée}
    other {Autre}
  }`,
  '/book/bike-select.summary.additional.value': '{ additional, select, true {oui} false {non} other {Erreur} }',
  '/book/bike-select.summary.duration.value': '{ duration, time, short }',
  '/book/bike-select.summary.slot.value': '{ slot, date, ::yMdHm }',

  // NEW TRADS 2020-05-18
  'header.sign-in': 'Je me connecte',
  'header.sign-up': 'Je m\'inscris',
  'header.signout': 'Je me déconnecte',
  'header.account': 'Mon compte',
  'header.book': 'Je prends rendez-vous',
  'header.equipments': 'Mes vélos',
  'header.pro': 'J\'ai une flotte pro',
  'header.backoffice': 'Je suis réparateur',
  'header.interventions': 'Mes interventions',
  'header.title': 'Page d\'accueil',
  'header.burger': 'Menu',

  'Footer.legal.title': 'Les informations légales',
  'Footer.legal.terms': 'Conditions Générales d\'Utilisation',
  'Footer.legal.privacy': 'Politique de confidentialité',
  'Footer.legal.mentions': 'Mentions légales',
  'Footer.commercial.title': 'Le plan pour s\'y retrouver',
  'Footer.commercial.subscription': 'Flotte professionnelle',
  'Footer.commercial.faq': 'Foire Aux Questions',
  'Footer.commercial.howItWorks': 'Comment ça marche ?',
  'Footer.commercial.backoffice': 'Accès réparateur',
  'Footer.commercial.offers': 'Offres de service',
  'Footer.commercial.vla': 'Velogik Les Ateliers',
  'Footer.social.title': 'Rouler avec nous sur',
  'Footer.social.instagram': 'Instagram',
  'Footer.social.facebook': 'Facebook',
  'Footer.social.twitter': 'Twitter',
  'Footer.version': `{ version, select,
    SNAPSHOT {{ version }}
    DEV {Dev}
    other {v{ version }}
  }`,

  '/home.jumbotron.title': 'Pneu à plat, frein fatigué, chaîne usée ?',
  '/home.jumbotron.subTitle': 'Nous avons le réparateur qu’il vous faut. Vélocenter, toujours là pour vous et votre vélo.',
  '/home.search.equipmentType.label': 'Mon vélo',
  '/home.search.equipmentType.placeholder': 'Standard, électrique ou cargo ?',
  '/home.search.equipmentType.option.vae': 'Vélo Assistance Electrique',
  '/home.search.equipmentType.option.standard': 'Vélo standard',
  '/home.search.equipmentType.option.cargoae': 'Vélo cargo électrique',
  '/home.search.equipmentType.option.cargo': 'Vélo cargo',
  '/home.search.equipmentType.option.other': 'Autre',
  '/home.search.repairLocation.label': 'Mon rendez-vous',
  '/home.search.repairLocation.option.shop': 'En atelier',
  '/home.search.repairLocation.option.home': 'A domicile',
  '/home.search.repairLocation.placeholder': 'Chez vous ou chez nous ?',
  '/home.search.address.label': 'Mon adresse',
  '/home.search.address.placeholder': 'Toujours un réparateur près de chez vous',
  'AddressInput.loading': 'Chargement des adresses...',
  'AddressInput.noResult': 'Aucun résultat',
  '/home.search.action.submit': 'Je trouve un réparateur',

  '/home.howItWorks.title': 'Vélocenter : comment ça marche ?',
  '/home.howItWorks.subTitle': 'Faire réparer mon vélo par un pro',
  '/home.howItWorks.search': 'Aïe c’est la panne ! Je cherche un pro pour réparer mon vélo',
  '/home.howItWorks.diagnosis': 'Changement de chaîne, de freins, révision... J’explique mes besoins',
  '/home.howItWorks.appointment': 'Je prends rendez-vous en ligne sans rien avancer et je reçois mon devis',
  '/home.howItWorks.workshop': 'Le jour J, je me rends en atelier ou j’attends le réparateur chez moi à l’heure du rendez-vous',
  '/home.howItWorks.repaired': 'Mon vélo est comme neuf !',
  '/home.howItWorks.knowMore': 'En savoir plus',

  '/home.coveredServices.title': 'Vélocenter : nos services ',
  '/home.coveredServices.subTitle': 'Tout ce que nos réparateurs peuvent faire pour vous et votre vélo',
  '/home.coveredServices.knowMore': 'En savoir plus',
  '/home.coveredServices.services': `
    <li>Préparer et mettre en service</li>
    <li>Vous proposer des accessoires neufs</li>
    <li>Réparer votre vélo</li>
    <li>Entretenir et réviser</li>
    <li>Vendre des pièces détachées</li>
    <li>Monter vos pièces détachées</li>
    <li>Identifier votre vélo</li>
  `,

  '/faq.pageTitle': 'Foire Aux Questions',
  '/faq.contact': 'Je n\'ai pas trouvé la réponse à ma question',
  '/faq.questions': `
    <Accordion>
      <AccordionTitle>Les types de réparations supportées</AccordionTitle>
      <AccordionContent>
        <title>Quels types de réparations faites-vous ?</title>
        <p>Les ateliers et techniciens listés sur la plateforme réalisent toutes les prestations d’entretien et de réparation d’un vélo. Révision, réglage des freins et/ou des vitesses, changement de roues et/ou de rayon, dévoilage, changement du dérailleur, pose d’accessoires… </p>
        <title>Puis-je faire une révision de mon vélo ?</title>
        <p>Bien sûr, les ateliers et techniciens proposant cette prestation de service révisent tous types de vélos. Nous vous proposons plusieurs forfaits en fonction de votre usage. Et même des formules d’abonnements pour rouler en toute confiance. </p>
        <title>Quand dois-je faire réviser mon vélo ?</title>
        <p>Tout dépend de l’usage que vous avez de votre vélo. Si vous vous en servez occasionnellement, un entretien annuel suffit. Vous pouvez le directement planifier en ligne. Pour les cyclistes plus assidus, il est préférable de faire réviser son vélo tous les 6 mois, voire plus en fonction de votre pratique. Les ateliers et techniciens pourront vous conseiller sur la fréquence adaptée à votre usage.</p>
        <title>L’entretien du vélo est-il obligatoire ?</title>
        <p>Non, ce n’est pas une obligation. Mais il est recommandé d’entretenir régulièrement son vélo pour rouler confortablement et en sécurité. Vous évitez ainsi les pannes et les immobilisations.</p>
        <title>Réparez- vous les vélos pour enfants ? </title>
        <p>Les ateliers et techniciens listés sur la plateforme s'occupent de tous les cyclistes, amateurs comme passionnés, dès le plus jeune âge.</p>
      </AccordionContent>
    </Accordion>
    <Accordion>
      <AccordionTitle>Prendre un rendez-vous d'intervention</AccordionTitle>
      <AccordionContent>
        <title>Puis-je faire réparer mon vélo à mon domicile ou sur mon lieu de travail</title>
        <p>Oui, il suffit de renseigner l'adresse que vous souhaitez lors de votre réservation et de ne pas oublier de sélectionner que vous souhaitez une prestation à domicile.</p>
        <title>En cas d’intervention à domicile, vais-je payer des frais de déplacement ? </title>
        <p>Cela dépend de l'atelier ou du technicien que vous allez choisir. Certains offrent le déplacement !</p>
        <title>Que comprennent les tarifs indiqués sur le devis généré sur le site ?</title>
        <p>Les devis comprennent la main-d'œuvre hors pièces, frais de déplacement compris (applicables sur les interventions à domicile).</p>
        <title>Dois-je payer en ligne pour réserver ?</title>
        <p>Non, le paiement de la prestation se fera auprès du technicien ou atelier, par chèque, espèce ou carte bleue.</p>
        <title>Dois-je prendre RDV en ligne?</title>
        <p>Pas forcément ! Il est conseillé de prendre RDV en ligne pour vous assurer une disponibilité. Mais vous pouvez également passer directement en Atelier ou contacter l'atelier par Téléphone pour prendre rendez-vous.</p>
        <title>Dois-je créer un compte client ?</title>
        <p>Oui c’est nécessaire pour prendre un RDV en ligne. Si vous prenez RDV par téléphone, votre réparateur créera le compte client avec vous. C’est important pour pouvoir suivre les réparations et les interventions dans le temps.</p>
        <title>Dois-je payer en ligne pour réserver ?</title>
        <p>Non, le paiement de la prestation se fera auprès du technicien ou atelier, par chèque, espèce ou carte bleue.</p>
        <title>Comment annuler un rdv ?</title>
        <p>Vous pouvez contacter directement l’atelier ou le technicien avec lequel vous avez RDV. Merci d’annuler le rdv plus tôt possible afin de libérer des créneaux pour ceux qui en auraient besoin. </p>
        <title>Je ne sais pas quelle réparation choisir lors de ma prise de RDV, comment faire ?</title>
        <p>Choisissez la catégorie “Je ne sais pas identifier ma panne” lors de la prise de RDV. Votre besoin sera ainsi précisé par un technicien en Atelier. Vous pouvez également venir sans RDV. Un réparateur pourra identifier ce qui ne fonctionne pas. </p>
        <title>Puis-je prendre RDV par téléphone ou par email? </title>
        <p>Oui, vous pouvez contacter par email ou par téléphone l’atelier ou le technicien le plus proche de chez vous directement depuis sa page profil.</p>
      </AccordionContent>
    </Accordion>
    <Accordion>
      <AccordionTitle>Déroulement d'une intervention</AccordionTitle>
      <AccordionContent>
        <title>Puis-je fournir moi-même les pièces de rechange ?</title>
        <p>Oui, les techniciens et ateliers installent les pièces que vous fournissez et s’assurent du bon fonctionnement de votre vélo.</p>
        <title>Comment être sûr que mon rdv est confirmé ?</title>
        <p>Vous recevez un mail de confirmation avec les modalités du RDV. Si vous n’avez rien dans votre boite email, pensez à vérifier vos spams.</p>
        <title>Comment se déroule une intervention en Atelier ?</title>
        <p>Tout démarre par un pré-diagnostic qui permet d’estimer le montant des réparations et le délai d’intervention. Le client donne son accord et l’intervention est planifiée. Elle peut se faire dans l’instant et le propriétaire du vélo patiente avec un café. Si une immobilisation plus longue s’avère nécessaire, un vélo de courtoisie est proposé. Le client est appelé dès que son vélo est prêt. Et reçoit aussi un email pour les modalités de restitution.</p>
        <title>Comment se déroule une intervention à mon domicile ?</title>
        <p>Vous convenez d’un lieu de RDV avec le mécanicien cycles. Il peut s’agir de votre domicile, de votre lieu de travail ou de l’endroit de votre choix. Le réparateur se déplace et vous rencontre afin de réaliser le pré-diagnostic. Il évalue ainsi les réparations et le délai d’intervention. Une fois la réparation effectuée, il vous tient informé et procède au règlement par carte bancaire. </p>
        <title>Puis-je laisser mon vélo dans la rue en vue de l’intervention ?</title>
        <p>Les ateliers et techniciens n’interviennent pas sur le vélo en l’absence de son propriétaire. Nous avons donc besoin de convenir d’un lieu de RDV où vous serez présent en vue des réparations.</p>
        <title>Dois-je préparer mon vélo avant une intervention ? </title>
        <p>Non, vous n’avez rien à faire hormis prendre rdv. Nos experts s’occupent de tout.</p>
      </AccordionContent>
    </Accordion>
    <Accordion>
      <AccordionTitle>Problème avec une intervention</AccordionTitle>
      <AccordionContent>
        <title>Que faire si le réparateur ne vient pas ?</title>
        <p>Vous pouvez rappeler le numéro de téléphone par lequel le RDV a été pris. Si vous avez pris RDV en ligne, contactez l’Atelier dont dépend le réparateur.</p>
        <title>Comment signaler un problème suite à une intervention ?</title>
        <p>Pour signaler un problème avec une intervention, cliquez sur “Signaler un problème“ depuis le menu "Mes interventions". N'hésitez pas à nous donner un maximum de détails sur le problème rencontré. Nous répondrons dans les plus brefs délais.</p>
        <title>H2 L’intervention est elle garantie ?</title>
        <p>Référez-vous aux détails du profil du technicien ou atelier. La plupart propose des garanties de 3,6 ou 12 mois.</p>
      </AccordionContent>
    </Accordion> 
    <Accordion>
      <AccordionTitle>Mon compte personnel</AccordionTitle>
      <AccordionContent>
        <title>Comment sont conservées et utilisées mes données personnelles ?</title>
        <p>Nous collectons seulement les informations nécessaires à la réalisation de la prestation de réparation. Vos données restent entre vous et l’Atelier. Elles ne sont pas revendues à des tiers ou même transmise à d’autres Ateliers. Vous conservez un droit d’accès et de modification.</p>
        <title>J’ai oublié mon mot de passe de mon client</title>
        <p>Vous pouvez récupérer votre mot de passe, en cliquant sur “mot de passe oublié” sous le formulaire de connexion. </p>
        <title>Je souhaite supprimer mon compte</title>
        <p>Contactez-nous via le formulaire de contact. Nous nous occuperons de supprimer votre compte et supprimer vos données.</p>
      </AccordionContent>
    </Accordion>
  `,

  '/how-it-works.CoveredServices.content': `
    <block>
      <title>Mise en service</title>
      <content>Un vélo neuf prêt à rouler ? Nos pros montent et règlent votre vélo au mm près pour s’élancer en toute sécurité.</content>
    </block>
    <block>
      <title>Vente de vélo neuf</title>
      <content>Passionnés de vélos, nos réparateurs sélectionnent les meilleurs équipements pour vous.</content>
    </block>
    <block>
      <title>Réparation</title>
      <content>Un pneu raplapla, une roue qui coince ou des freins qui couinent : nous réparons toutes les pannes pour vous remettre en selle au plus vite.</content>
    </block>
    <block>
      <title>Entretien et révision</title>
      <content>Nous prenons soin de votre vélo pour vous éviter les mauvaises surprises.</content>
    </block>
    <block>
      <title>Ventes de pièces détachées</title>
      <content>Ajouter un porte-bagage avant, fixer des phares ou changer de batterie ? Nos réparateurs sont toujours dispos pour offrir une nouvelle vie à votre vélo.</content>
    </block>
    <block>
      <title>Montage de pièces détachées</title>
      <content>Un siège bébé à installer ? Un vélo cargo à équiper ? La crème de la crème de nos réparateurs équipe votre vélo plus vite que son ombre.</content>
    </block>
    <block>
      <title>Sécurité du vélo</title>
      <content>Pour éviter le vol de votre vélo, nos réparateurs marquent vos vélos d’occasion : objectif traçabilité + sécurité.</content>
    </block>
  `,

  '/pro.jumbotron.title': 'Flottes de vélo professionnelle',
  '/pro.jumbotron.content': `
<line>Vous êtes propriétaire ou gestionnaire d’une flotte de vélos ?</line>
<line>Choisir un expert de la maintenance cycle pour les pros, c’est la garantie d’avoir une flotte opérationnelle 24h/24h et 7j/7j.</line>
`,
  '/pro.description.title': 'Vélos standards, pliants, VAE, VLS, Vélos cargos, consignes ou stations... Vélogik est toujours là pour vous et les vélos de vos équipes.',
  '/pro.description.content': 'Entretenir sa flotte de vélos ? Oui, c’est indispensable pour assurer la sécurité de vos cyclistes mais aussi leur offrir la disponibilité et la flexibilité qu’ils attendent. C’est également la garantie de faire durer vos équipements plus longtemps. Créé en 2008, Vélogik est le spécialiste de la maintenance cycle des flottes professionnelles.',
  '/pro.points': `
<point><icon>icons/bike-standard-black.svg</icon>40 000 vélos en maintenance</point>
<point><icon>icons/clock-black.svg</icon>Taux de disponibilité des vélos garanti</point>
<point><icon>icons/euro-black.svg</icon>Jusqu’à 30 % d’économies de maintenance</point>
<point><icon>icons/timelapse-black.svg</icon>Des process optimisés, certifiés ISO 9001 et éco-responsables</point>
<point><icon>icons/key-black.svg</icon>Des solutions logicielles pour piloter votre flotte</point>
<point><icon>icons/genderfluid-face-black.svg</icon>Des formations pour faire grandir vos équipes</point>
  `,
  '/pro.cta': 'Je veux plus d\'infos',
  '/vla.pageTitle': 'Vélogik Les Ateliers, le réseau de franchise spécialisé en réparation cycle',
  '/vla.informations.title': 'Vélogik Les Ateliers, c\'est quoi ?',
  '/vla.informations.content': 'Se déplacer à vélo ? Oui nous sommes de plus en plus nombreux à le faire, et c’est tant mieux ! Mais comment trouver facilement un mécanicien disponible pas loin de chez soi quand on a besoin d’un coup de main pour réparer son vélo ? Le réseau Vélogik Les Ateliers rassemble les experts en mécanique mordus de vélo. Quel que soit le modèle de vélo et sa technologie, chez Vélogik Les Ateliers il y a toujours un passionné sur qui compter pour l’entretien et la réparation.',
  '/vla.informations.cta.action': 'Je veux travailler dans le réseau',
  '/vla.services.title': 'Les services',
  '/vla.services.content': `
<list>
  <item>Des outils digitaux sur mesure séveloppés pour faciliter le métier sur le terrain.</item>
  <item>Une plateforme dédiée pour piloter toute votre activité : Vélocenter.</item>
  <item>Une centrale d'achats performante bénéficiant de tarifs et des délais compétitifs.</item>
  <item>Un pack de lancement pour démarrer votre activité (atelier mobile, stock de pièces, accessoires...).</item>
  <item>Un pack de communication à votre image pour communiquer sur le lancement de votre atelier.</item>
  <item>Des formations pour le dirigeant et ses équipes (évaluation des compétences, formations personnalisées...).</item>
  <item>Une démarche environnementale pour limiter l'impact écologique de votre atelier (gestion des déchets, déplacements propres...).</item>
</list>
  `,
  '/vla.feedbacks.title': 'Les avantages',
  '/vla.feedbacks.content': `
<item>
  <userName>Réparateur de vélo : oui je le veux !</userName>
  <message>Vous adorez la mécanique ? Rejoignez le réseau des experts de la maintenance depuis plus de 12 ans. Les pros du vélo de Vélogik Les Ateliers ont tous le même mot d’ordre : assurer des services de qualité pour les vélos de leurs clients.</message>
</item>
<item>
  <userName>La force du collectif, un réseau national</userName>
  <message>Tenté par le défi entrepreneurial ? Le réseau Vélogik Les Ateliers se développe à vitesse grand V partout en France. Un marché en plein développement et une occasion en or de se lancer bien entouré.</message>
</item>
<item>
  <userName>Réparation de vélo option digital</userName>
  <message>Le réseau Vélogik Les Ateliers c’est des solutions digitales clé en main pour vous permettre de vous consacrer pleinement à votre métier. Leur objectif ? Vous faciliter le quotidien, développer votre activité et donc votre chiffre d’affaires.</message>
</item>
<item>
  <userName>L’énergie d’un collectif</userName>
  <message>Dans le réseau Vélogik Les Ateliers, on mise tout sur la coopération pour se faire grandir les uns les autres. Passion, humain, expertise : nos 3 valeurs essentielles en disent long sur notre état d’esprit.</message>
</item>
  `,
  '/vla.contact.title': 'Contactez Velogik Les Ateliers',
  '/vla.contact.subTitle': 'Je veux travailler dans le réseau',
  '/vla.contact.email.label': 'Mon email',
  '/vla.contact.email.placeholder': 'votre@adresse.com',
  '/vla.contact.phoneNumber.label': 'Mon numéro de téléphone',
  '/vla.contact.phoneNumber.placeholder': ' ',
  '/vla.contact.description.label': 'Mon message',
  '/vla.contact.description.placeholder': ' ',
  '/vla.contact.submit.action': 'J\'envoie ma demande',

  '/sign-in.pageTitle': 'Bienvenue ! ',
  '/sign-in.blockTitle': 'Pour vous connecter, dites-nous tout :',
  '/sign-in.email.label': 'Votre email',
  '/sign-in.email.placeholder': 'votre@email.com',
  '/sign-in.password.label': 'Un mot de passe',
  '/sign-in.password.placeholder': ' ',
  '/sign-in.forgotPassword': 'Oups, j’ai oublié mon mot de passe !',
  '/sign-in.persist.label': 'Se souvenir de moi',
  '/sign-in.submit': 'Je me connecte',
  '/sign-in.sign-up': 'Je m\'inscris',
  '/sign-in.catchy.phrase': 'Plutôt dompteur de col ou zigzagueur de square ?',
  '/sign-in.connected': 'Connexion réussie !',

  '/sign-up.pageTitle': 'Bienvenue chez Vélocenter !',
  '/sign-up.blockTitle': 'Donnez-nous quelques infos sur vous et nous créons immédiatement votre compte.',
  '/sign-up.firstName.label': 'Mon prénom',
  '/sign-up.firstName.placeholder': ' ',
  '/sign-up.lastName.label': 'Mon nom',
  '/sign-up.lastName.placeholder': ' ',
  '/sign-up.email.label': 'Mon email',
  '/sign-up.email.placeholder': 'votre@email.com',
  '/sign-up.phoneNumber.label': 'Mon téléphone (optionnel)',
  '/sign-up.phoneNumber.placeholder': '+33',
  '/sign-up.phoneNumber.validation.valid': 'Téléphone verifié',
  '/sign-up.phoneNumber.validation.invalid': 'J\'accepte de recevoir les communications par SMS',
  '/sign-up.phoneNumber.validation.sendCode': 'Je vérifie',
  '/sign-up.phoneNumber.validation.codesent.label': 'Code de confirmation recu par SMS',
  '/sign-up.phoneNumber.validation.codesent.placeholder': '123456',
  '/sign-up.phoneNumber.validation.resend': 'Renvoyer un nouveau code',
  '/sign-up.phoneNumber.validation.confirm': 'Je confirme',
  '/sign-up.password.label': 'Un mot de passe',
  '/sign-up.password.placeholder': ' ',
  '/sign-up.confirmPassword.label': 'Je confirme mon mot de passe',
  '/sign-up.confirmPassword.placeholder': ' ',
  '/sign-up.newsletter.label': 'Oui je souhaite recevoir l\'actu de mon réparateur',
  '/sign-up.submit': 'Je m\'inscris',
  '/sign-up.sign-in': 'Je me connecte',
  '/sign-up.catchy.phrase': 'Vélocenter toujours là pour vous et votre vélo.',

  '/forgot-password.pageTitle': 'Mot de passe oublié',
  '/forgot-password.email.label': 'Email',
  '/forgot-password.email.placeholder': 'votre@email.tld',
  '/forgot-password.valid': 'Nouveau mot de passe',
  '/forgot-password.success': 'Un email vient de vous être envoyé.',

  '/new-password.pageTitle': 'Mot de passe oublié',
  '/new-password.username.label': 'Email',
  '/new-password.password.label': 'Mot de passe',
  '/new-password.confirmPassword.label': 'Confirmation du mot de passe',
  '/new-password.valid': 'Valider mon nouveau mot de passe',
  '/new-password.success': 'Votre email a bien été réinitialisé. Vous pouvez maintenant vous connecter.',

  // /search-shops
  '/search-shops.search.equipmentType.label': 'Mon vélo',
  '/search-shops.search.equipmentType.placeholder': 'Standard, électrique ou cargo ?',
  '/search-shops.search.equipmentType.option.vae': 'Vélo à Assistance Électrique',
  '/search-shops.search.equipmentType.option.standard': 'Vélo standard',
  '/search-shops.search.equipmentType.option.cargoae': 'Vélo cargo électrique',
  '/search-shops.search.equipmentType.option.cargo': 'Vélo cargo',
  '/search-shops.search.equipmentType.option.other': 'Autre',
  '/search-shops.search.repairLocation.label': 'Mon rendez-vous',
  '/search-shops.search.repairLocation.option.shop': 'En atelier',
  '/search-shops.search.repairLocation.option.home': 'A mon domicile',
  '/search-shops.search.repairLocation.placeholder': 'Chez vous ou chez nous ?',
  '/search-shops.search.address.label': 'Mon adresse',
  '/search-shops.search.address.placeholder': 'Votre point de départ ?',
  '/search-shops.search.action.submit': 'Je trouve un réparateur',

  '/search-shops.filters.repairSpecialization.title': 'Plus d\'infos sur mon vélo',
  '/search-shops.filters.repairSpecialization.cowboy': 'Vélo cowboy',
  '/search-shops.filters.repairSpecialization.road': 'Vélo de route',
  '/search-shops.filters.repairSpecialization.vtt': 'VTT',
  '/search-shops.filters.repairSpecialization.dutch': 'Vélo hollandais',
  '/search-shops.filters.repairSpecialization.lyingDown': 'Vélo position couchée',
  '/search-shops.filters.repairSpecialization.folding': 'Vélo pliant',
  '/search-shops.filters.repairServices.title': 'Je précise mon besoin',
  '/search-shops.filters.repairService.breakdown': 'Réparation cycle',
  '/search-shops.filters.repairService.overhaul': 'Révision & mise en service',
  '/search-shops.filters.repairService.spare': 'Montage pièce détachée',
  '/search-shops.filters.repairService.other': 'Autres prestations',
  '/search-shops.filters.r.title': 'Autour de moi',
  '/search-shops.filters.map.show': 'Afficher sur le plan',
  '/search-shops.filters.map.hide': 'Fermer le plan',

  '/search-shops.Shop.skills.location.shop': 'Reçoit à son atelier',
  '/search-shops.Shop.skills.location.home': 'Déplacement à domicile',
  '/search-shops.Shop.skills.service.breakdown': 'Réparation cycle',
  '/search-shops.Shop.skills.service.overhaul': 'Révision & mise en service',
  '/search-shops.Shop.skills.service.spare': 'Montage pièce détachée',
  '/search-shops.Shop.skills.service.other': 'Autres prestations',
  '/search-shops.Shop.skills.specialization.cowboy': 'Vélo cowboy',
  '/search-shops.Shop.skills.specialization.road': 'Vélo de route',
  '/search-shops.Shop.skills.specialization.vtt': 'VTT',
  '/search-shops.Shop.skills.specialization.dutch': 'Vélo hollandais',
  '/search-shops.Shop.skills.specialization.lyingDown': 'Vélo position couchée',
  '/search-shops.Shop.skills.specialization.folding': 'Vélo pliant',
  '/search-shops.Shop.availabilities.title': 'Prochaines disponibilités',
  '/search-shops.Shop.new': 'Nouveau',
  '/search-shops.Shop.location.distance': '({ distance, number, ::unit/meter })',
  '/search-shops.Shop.availabilities.slot': '{ slot, date, ::eeeedHm }',

  '/search-shops.MobileResultInfos.total': `{ total, select, 
    0 {Désolés nous n’avons pas de réparateurs à proximité vous proposer pour l’instant...} 
    1 {1 résultat}
    other {{total} résultats} 
  }`,
  '/search-shops.MobileSearchInput.summary': 'Votre recherche sur { radius } km',//TODO
  '/search-shops.MobileSearchInput.filters': 'Filtrer',
  '/search-shops.MobileSearchInput.modalTitle': 'Retour',
  '/search-shops.MobileSearchInput.repairSpecialization.title': 'Spécialisation',
  '/search-shops.MobileSearchInput.repairSpecialization.cowboy': 'Vélo cowboy',
  '/search-shops.MobileSearchInput.repairSpecialization.road': 'Vélo de route',
  '/search-shops.MobileSearchInput.repairSpecialization.vtt': 'VTT',
  '/search-shops.MobileSearchInput.repairSpecialization.dutch': 'Vélo hollandais',
  '/search-shops.MobileSearchInput.repairSpecialization.lyingDown': 'Vélo position couchée',
  '/search-shops.MobileSearchInput.repairSpecialization.folding': 'Vélo pliant',
  '/search-shops.MobileSearchInput.repairServices.title': 'Prestation de service',
  '/search-shops.MobileSearchInput.repairService.breakdown': 'Réparation cycle',
  '/search-shops.MobileSearchInput.repairService.overhaul': 'Révision & mise en service',
  '/search-shops.MobileSearchInput.repairService.spare': 'Montage pièce détachée',
  '/search-shops.MobileSearchInput.repairService.other': 'Autres prestations',
  '/search-shops.MobileSearchInput.r.title': 'Rayon de recherche',
  '/search-shops.MobileSearchInput.apply': 'Je recherche',

  '/shops/:shopId.pageTitle': 'Profil de l\'atelier',
  '/shops/:shopId.Summary.title': 'Détails de l\'atelier',
  '/shops/:shopId.Summary.new': 'Nouveau',
  '/shops/:shopId.Summary.actions.book': 'Je réserve',
  '/shops/:shopId.actions.book': 'Je réserve',
  '/shops/:shopId.Summary.actions.contact': 'Contacter',
  '/shops/:shopId.Skills.title': 'Les prestations de service',
  '/shops/:shopId.Skills.skills.location.shop': 'Prestation en atelier',
  '/shops/:shopId.Skills.skills.location.home': 'Déplacement à domicile',
  '/shops/:shopId.Skills.skills.service.breakdown': 'Réparation cycle',
  '/shops/:shopId.Skills.skills.service.overhaul': 'Révision & mise en service',
  '/shops/:shopId.Skills.skills.service.spare': 'Montage pièce détachée',
  '/shops/:shopId.Skills.skills.service.other': 'Autres prestations',
  '/shops/:shopId.Skills.skills.specialization.cowboy': 'Vélo cowboy',
  '/shops/:shopId.Skills.skills.specialization.road': 'Vélo de route',
  '/shops/:shopId.Skills.skills.specialization.vtt': 'VTT',
  '/shops/:shopId.Skills.skills.specialization.dutch': 'Vélo hollandais',
  '/shops/:shopId.Skills.skills.specialization.lyingDown': 'Vélo position couchée',
  '/shops/:shopId.Skills.skills.specialization.folding': 'Vélo pliant',
  '/shops/:shopId.Feedbacks.title': 'Les avis des clients',
  '/shops/:shopId.Feedbacks.empty': 'Aucun avis pour le moment !',
  '/shops/:shopId.Feedbacks.loadMore': 'Voir plus d\'avis',
  '/shops/:shopId.Availabilities.title': 'Les disponibilités de l\'atelier',
  '/shops/:shopId.Prices.title': 'La grille tarifaire de l\'atelier',
  '/shops/:shopId.Prices.filter.equipmentType.vae': 'Vélo électrique',
  '/shops/:shopId.Prices.filter.equipmentType.cargo': 'Vélo cargo',
  '/shops/:shopId.Prices.filter.equipmentType.cargoae': 'Vélo cargo électrique',
  '/shops/:shopId.Prices.filter.equipmentType.standard': 'Vélo standard',
  '/shops/:shopId.Availabilities.Calendar.noSlots': 'Prochaine disponibilité le {firstAvailabilitiy, date}',
  '/shops/:shopId.Availabilities.Calendar.noAvailabilities': 'Plus aucune disponibilité',

  '/shops/:shopId/contact.pageTitle': 'Contacter l\'atelier',
  '/shops/:shopId/contact.blockTitle': 'Une question sur une prestation ? Un besoin ? Dites tout à l\'atelier qui vous répondra par email sous les plus brefs délais :',
  '/shops/:shopId/contact.description.label': 'Mon message',
  '/shops/:shopId/contact.description.placeholder': ' ',
  '/shops/:shopId/contact.email.label': 'Mon adresse mail',
  '/shops/:shopId/contact.email.placeholder': 'mon@email.com',
  '/shops/:shopId/contact.submit': 'J’envoie ma demande',
  '/shops/:shopId/contact.success.text': 'Votre message a été envoyé avec succès à l\'atelier !',

  '/contact.pageTitle': 'Contacter le support',
  '/contact.blockTitle': 'Un problème ? Un besoin ? Dites-nous tout en 1 min pour que nous puissions trouver la solution qu’il vous faut :',
  '/contact.description.label': 'Mon message',
  '/contact.description.placeholder': 'Dites-nous en plus...',
  '/contact.email.label': 'Mon adresse mail',
  '/contact.email.placeholder': 'mon@email.tld',
  '/contact.askType.label': 'J\'ai besoin de...',
  '/contact.askType.placeholder': 'Sélectionnez une raison...',
  '/contact.askType.option.general': 'Poser une question à propos du site',
  '/contact.askType.option.bug': 'Remonter un bug',
  '/contact.askType.option.rgpd': 'Exercer mes droits (RGPD)',
  '/contact.askType.option.intervention': 'Remonter un problème avec une intervention',
  '/contact.askType.option.equipment': 'Remonter un problème avec mon vélo',
  '/contact.askType.option.client': 'Remonter un problème avec un client',
  '/contact.askType.option.service': 'Faire évoluer mon offre de service',
  '/contact.askType.option.fleet': 'Gérer la maintenance de ma flotte professionnelle',
  '/contact.askType.option.subscribe': 'Souscrire car je suis réparateur',
  '/contact.askType.option.other': 'Poser une autre question',
  '/contact.interventionId.label': 'Mon numéro d\'intervention',
  '/contact.interventionId.placeholder': '...',
  '/contact.submit': 'C’est tout bon ! J’envoie ma demande',
  '/contact.dirty.dismiss': 'J\'ai compris',
  '/contact.success.text': 'Votre message a bien été envoyé',

  '/equipments.pageTitle': 'Mes vélos',
  '/equipments.equipments.title': 'J\'enregistre mon vélo ou je choisis parmis mes vélos',
  '/equipments.equipments.label': `
    <brandTag>{brand}</brandTag>
    <equipmentTypeTag>
      {equipmentType, select,
        standard {Standard}
        vae {Vélo à assistance éléctrique}
        cargo {Vélo cargo}
        cargoae {Vélo cargo éléctrique}
        velocare {Vélo { business }}
        other {{ equipmentType }}
      }
    </equipmentTypeTag>
    <customNameTag>{customName}</customNameTag>
  `,
  '/equipments.equipments.new': '+ J\'ajoute un vélo',
  '/equipments.equipments.edit': 'Éditer mon vélo',
  '/equipments.equipments.view': 'Voir la fiche',

  '/equipments/new.pageTitle': 'J\'ajoute un vélo',
  '/equipments/new.equipmentType.title': 'Mon type de vélo *',
  '/equipments/new.category.regular': 'Grand public',
  '/equipments/new.category.pro': 'Professionnel (Velocare)',
  '/equipments/new.equipmentType.label.vae': 'Vélo à Assistance Électrique',
  '/equipments/new.equipmentType.label.standard': 'Vélo standard',
  '/equipments/new.equipmentType.label.cargo': 'Vélo cargo',
  '/equipments/new.equipmentType.label.cargoae': 'Vélo cargo électrique',
  '/equipments/new.equipmentType.label.other': 'Autre',
  '/equipments/new.business.label': 'Affaire',
  '/equipments/new.business.placeholder': 'Choisir ...',
  '/equipments/new.next': 'Etape suivante',
  '/equipments/new.previous': 'Etape précédente',
  '/equipments/new.identificationSystems.title': 'Son identification *',
  '/equipments/new.identificationSystem.label.none': 'Aucune identification',
  '/equipments/new.identificationSystem.label.bicycode': 'Bicycode',
  '/equipments/new.identificationSystem.label.paravol': 'Paravol',
  '/equipments/new.identificationSystem.label.recobike': 'Recobike',
  '/equipments/new.identificationSystem.label.avrysec': 'Avry-security',
  '/equipments/new.identificationSystem.label.velocenter': 'VelocenterID',
  '/equipments/new.identificationCode.label': 'Code d\'identification',
  '/equipments/new.identificationCode.placeholder': '00000000',
  '/equipments/new.informations.title': 'Les détails de mon vélo',
  '/equipments/new.brandId.label': 'Marque *',
  '/equipments/new.brandId.placeholder': 'Selectionner ...',
  '/equipments/new.brandId.filter': 'Chercher ...',
  '/equipments/new.brandId.option': `{value, select,
    otherBrand {Autre ...}
    loading {Chargement ...}
    other {{ value }}
  }`,
  '/equipments/new.brand.placeholder': 'Marque du vélo',
  '/equipments/new.customName.label': 'Nom personnalisé *',
  '/equipments/new.customName.placeholder': ' ',
  '/equipments/new.model.label': 'Modèle *',
  '/equipments/new.model.placeholder': ' ',
  '/equipments/new.purchaseYear.label': 'Année d\'achat (optionnel)',
  '/equipments/new.purchaseYear.placeholder': ' ',
  '/equipments/new.frontSprocket.label': 'Nombre de pignon à l\'avant (optionnel)',
  '/equipments/new.frontSprocket.placeholder': ' ',
  '/equipments/new.rearSprocket.label': 'Nombre de pignon à l\'arrière (optionnel)',
  '/equipments/new.rearSprocket.placeholder': ' ',
  '/equipments/new.wheelSize.label': 'Taille des roues (optionnel)',
  '/equipments/new.wheelSize.placeholder': ' ',
  '/equipments/new.create': 'J\'enregistre mon vélo',
  '/equipments/new.dirty.dismiss': 'J\'ai compris',
  '/equipments/new.pro.create': 'J\'enregistre mon vélo',

  '/equipments/:equipmentId/edit.pageTitle': 'Modifier mon vélo',
  '/equipments/:equipmentId/edit.equipmentType.title': 'Mon type d\'équipement *',
  '/equipments/:equipmentId/edit.category.regular': 'Grand public',
  '/equipments/:equipmentId/edit.category.pro': 'Professionnel (Velocare)',
  '/equipments/:equipmentId/edit.equipmentType.label.vae': 'Vélo à Assistance Électrique',
  '/equipments/:equipmentId/edit.equipmentType.label.standard': 'Vélo standard',
  '/equipments/:equipmentId/edit.equipmentType.label.cargo': 'Vélo cargo',
  '/equipments/:equipmentId/edit.equipmentType.label.cargoae': 'Vélo cargo électrique',
  '/equipments/:equipmentId/edit.equipmentType.label.other': 'Autre',
  '/equipments/:equipmentId/edit.next': 'Etape suivante',
  '/equipments/:equipmentId/edit.previous': 'Etape précédente',
  '/equipments/:equipmentId/edit.identificationSystems.title': 'Son Identification *',
  '/equipments/:equipmentId/edit.identificationSystem.label.none': 'Aucune identification',
  '/equipments/:equipmentId/edit.identificationSystem.label.bicycode': 'Bicycode',
  '/equipments/:equipmentId/edit.identificationSystem.label.paravol': 'Paravol',
  '/equipments/:equipmentId/edit.identificationSystem.label.recobike': 'Recobike',
  '/equipments/:equipmentId/edit.identificationSystem.label.avrysec': 'Avry-security',
  '/equipments/:equipmentId/edit.identificationSystem.label.velocenter': 'VelocenterID',
  '/equipments/:equipmentId/edit.identificationCode.label': 'Code d\'identification',
  '/equipments/:equipmentId/edit.identificationCode.placeholder': '00000000',
  '/equipments/:equipmentId/edit.informations.title': 'Les détails de mon vélo',
  '/equipments/:equipmentId/edit.brandId.label': 'Marque *',
  '/equipments/:equipmentId/edit.brandId.placeholder': 'Selectionner ...',
  '/equipments/:equipmentId/edit.brandId.filter': 'Chercher ...',
  '/equipments/:equipmentId/edit.brandId.option': `{value, select,
    otherBrand {Autre ...}
    loading {Chargement ...}
    other {{ value }}
  }`,
  '/equipments/:equipmentId/edit.brand.placeholder': 'Marque du vélo',
  '/equipments/:equipmentId/edit.customName.label': 'Nom personnalisé *',
  '/equipments/:equipmentId/edit.customName.placeholder': ' ',
  '/equipments/:equipmentId/edit.model.label': 'Modèle *',
  '/equipments/:equipmentId/edit.model.placeholder': ' ',
  '/equipments/:equipmentId/edit.purchaseYear.label': 'Année d\'achat',
  '/equipments/:equipmentId/edit.purchaseYear.placeholder': ' ',
  '/equipments/:equipmentId/edit.frontSprocket.label': 'Nombre de pignon à l\'avant',
  '/equipments/:equipmentId/edit.frontSprocket.placeholder': ' ',
  '/equipments/:equipmentId/edit.rearSprocket.label': 'Nombre de pignon à l\'arrière',
  '/equipments/:equipmentId/edit.rearSprocket.placeholder': ' ',
  '/equipments/:equipmentId/edit.wheelSize.label': 'Taille des roues',
  '/equipments/:equipmentId/edit.wheelSize.placeholder': ' ',
  '/equipments/:equipmentId/edit.create': 'Je modifie mon vélo',
  '/equipments/:equipmentId/edit.dirty.dismiss': 'J\'ai compris',

  '/equipments/:equipmentId.pageTitle': 'Mon vélo',
  '/equipments/:equipmentId.tabs.tabs.informations': 'Détails',
  '/equipments/:equipmentId.tabs.tabs.interventions': 'Interventions',
  '/equipments/:equipmentId.informations.title': 'Mon vélo en détails',
  '/equipments/:equipmentId.informations.equipmentTypeValue.label': 'Type de vélo',
  '/equipments/:equipmentId.informations.equipmentTypeValue.value': `{ equipmentTypeValue, select,
    vae {Vélo à Assistance Electrique}
    standard {Vélo standard}
    cargo {Vélo cargo}
    cargoae {Vélo cargo électrique}
    velocare {Vélo {business}}
    other {Type inconnu}
  }`,
  '/equipments/:equipmentId.informations.identificationSystemValue.label': 'Identification',
  '/equipments/:equipmentId.informations.identificationSystemValue.value': `{ equipmentTypeValue, select,
    velocare {{ velocareIdentifier }}
    other {{identificationSystemValue, select,
      bicycode {Bicycode}
      paravol {Paravol}
      recobike {Recobike}
      avrysec {Avry-security}
      velocenter {VelocenterID}
      none {Aucune identification}
      other {-}
    }}
  }`,
  '/equipments/:equipmentId.informations.identificationCode.label': 'Code d\'identification',
  '/equipments/:equipmentId.informations.identificationCode.empty': '-',
  '/equipments/:equipmentId.informations.brand.label': 'Marque',
  '/equipments/:equipmentId.informations.brand.empty': '-',
  '/equipments/:equipmentId.informations.model.label': 'Modèle',
  '/equipments/:equipmentId.informations.model.empty': '-',
  '/equipments/:equipmentId.informations.purchaseYear.label': 'Année d\'achat',
  '/equipments/:equipmentId.informations.purchaseYear.empty': '-',
  '/equipments/:equipmentId.informations.frontSprocket.label': 'Nombre de pignon à l\'avant',
  '/equipments/:equipmentId.informations.frontSprocket.empty': '-',
  '/equipments/:equipmentId.informations.rearSprocket.label': 'Nombre de pignon à l\'arrière',
  '/equipments/:equipmentId.informations.rearSprocket.empty': '-',
  '/equipments/:equipmentId.informations.wheelSize.label': 'Taille des roues',
  '/equipments/:equipmentId.informations.wheelSize.empty': '-',
  '/equipments/:equipmentId.intervention.title': 'Intervention { interventionId }',
  '/equipments/:equipmentId.intervention.details': 'Détails de l\'intervention',
  '/equipments/:equipmentId.result.title': 'Les interventions de mon vélo',
  '/equipments/:equipmentId.result.noResult': 'Ce vélo n\'a pas encore d\'intervention. Il est grand temps d\'en planifier une !',
  '/equipments/:equipmentId.edit': 'Je modifie la fiche',

  '/book/bike-select.pageTitle': 'Mon vélo',
  '/book/bike-select.equipments.title': 'J\'enregistre mon vélo ou je choisis parmis mes vélos',
  '/book/bike-select.equipments.new': '+ J\'ajoute un vélo',
  '/book/bike-select.equipments.submit': 'Je passe à l\'étape suivante',
  'error.front.noSelectedEquipment': 'Oups ! Il nous manque le vélo que vous souhaitez lier à ce rendez-vous ! Pouvez-vous le sélectionner ?',
  '/book/bike-select.summary.title': 'Mon récapitulatif',
  '/book/bike-select.summary.repairLocation.label': 'Type de prestation',
  '/book/bike-select.summary.address.label': 'Adresse de l\'intervention',
  '/book/bike-select.summary.equipmentType.label': 'Mon vélo',
  '/book/bike-select.summary.repairServices.label': 'Prestation de service',
  '/book/bike-select.summary.repairServicesDetails.label': 'Détail de prestation',
  '/book/bike-select.summary.additional.label': 'Révision complémentaire',
  '/book/bike-select.summary.duration.label': 'Durée de l\'intervention',
  '/book/bike-select.summary.slot.label': 'Date et heure',

  '/book/bike-select/new.pageTitle': 'J\'ajoute un nouveau vélo',
  '/book/bike-select/new.equipmentType.title': 'Type d\'équipement *',
  '/book/bike-select/new.category.regular': 'Grand public',
  '/book/bike-select/new.category.pro': 'Professionnel (Velocare)',
  '/book/bike-select/new.equipmentType.label.vae': 'Vélo à Assistance Électrique',
  '/book/bike-select/new.equipmentType.label.standard': 'Vélo standard',
  '/book/bike-select/new.equipmentType.label.cargo': 'Vélo cargo',
  '/book/bike-select/new.equipmentType.label.cargoae': 'Vélo cargo électrique',
  '/book/bike-select/new.equipmentType.label.other': 'Autre',
  '/book/bike-select/new.next': 'Je passe à l\'étape suivante',
  '/book/bike-select/new.previous': 'Étape précédente',
  '/book/bike-select/new.identificationSystems.title': 'Identification *',
  '/book/bike-select/new.identificationSystem.label.none': 'Aucune identification',
  '/book/bike-select/new.identificationSystem.label.bicycode': 'Bicycode',
  '/book/bike-select/new.identificationSystem.label.paravol': 'Paravol',
  '/book/bike-select/new.identificationSystem.label.recobike': 'Recobike',
  '/book/bike-select/new.identificationSystem.label.avrysec': 'Avry-security',
  '/book/bike-select/new.identificationSystem.label.velocenter': 'VelocenterID',
  '/book/bike-select/new.identificationCode.label': 'Code d\'identification',
  '/book/bike-select/new.identificationCode.placeholder': '00000000',
  '/book/bike-select/new.informations.title': 'Détails de l\'équipement',
  '/book/bike-select/new.brandId.label': 'Marque *',
  '/book/bike-select/new.brandId.placeholder': 'Selectionner ...',
  '/book/bike-select/new.brandId.filter': 'Chercher ...',
  '/book/bike-select/new.brandId.option': `{value, select,
    otherBrand {Autre ...}
    loading {Chargement ...}
    other {{ value }}
  }`,
  '/book/bike-select/new.brand.placeholder': 'Marque du vélo',
  '/book/bike-select/new.customName.label': 'Nom personnalisé *',
  '/book/bike-select/new.customName.placeholder': ' ',
  '/book/bike-select/new.model.label': 'Modèle *',
  '/book/bike-select/new.model.placeholder': ' ',
  '/book/bike-select/new.purchaseYear.label': 'Année d\'achat',
  '/book/bike-select/new.purchaseYear.placeholder': ' ',
  '/book/bike-select/new.frontSprocket.label': 'Nombre de pignon à l\'avant',
  '/book/bike-select/new.frontSprocket.placeholder': ' ',
  '/book/bike-select/new.rearSprocket.label': 'Nombre de pignon à l\'arrière',
  '/book/bike-select/new.rearSprocket.placeholder': ' ',
  '/book/bike-select/new.wheelSize.label': 'Taille des roues',
  '/book/bike-select/new.wheelSize.placeholder': ' ',
  '/book/bike-select/new.create': 'Je créé mon vélo',

  '/book/summary.pageTitle': 'Confirmation de rendez-vous',
  '/book/summary.details.title': 'Mon récapitulatif',
  '/book/summary.details.repairLocation.label': 'Type de prestation',
  '/book/summary.details.address.label': 'Adresse de l\'intervention',
  '/book/summary.details.equipmentType.label': 'Mon vélo',
  '/book/summary.details.repairServices.label': 'Prestation de service',
  '/book/summary.details.repairServicesDetails.label': 'Détail de prestation',
  '/book/summary.details.additional.label': 'Révision complémentaire',
  '/book/summary.details.duration.label': 'Durée de l\'intervention',
  '/book/summary.details.slot.label': 'Date et heure',
  '/book/summary.submit': 'Je confirme mon rendez-vous',
  '/book/summary.search': 'Chercher un autre Atelier',
  '/book/summary.retry': 'Réessayer',

  '/book/summary.details.repairLocation.value': '{ repairLocation, select, home {A domicile} shop {En atelier} other {Erreur} }',
  '/book/summary.details.address.value': '{ addressFull }',
  '/book/summary.details.equipmentType.value': `{ equipmentType, select,
    standard {Vélo standard}
    cargo {Vélo cargo}
    cargoae {Vélo cargo électrique}
    vae {Vélo à Assistance Électrique}
    other {{ equipmentType }}
  }`,
  '/book/summary.details.repairServices.value': `{ repairService, select,
    breakdown {Panne}
    overhaul {Révision ou mise à la route}
    spare {Installation pièce détachée}
    other {Autre}
  }`,
  '/book/summary.details.repairServicesDetails.value': `{ repairServiceDetails, select,
    speed {Vitesse}
    crankset {Pédalier}
    flatTire {Pneu à plat}
    brokenWheel {Roue cassée}
    brakeAdjustment {Disfonctionnement des freins}
    brokenBrakes {Freins cassés}
    battery {Batterie}
    diag {Diagnostic}
    overhaul {Révision}
    setup {Mise en service}
    spareMine {Pièce détachée fournie}
    spareBuy {Pièce détachée à acheter}
    kit {Kit de motorisation}
    accessory {Petit accessoire}
    idSetup {Système d'identification}
    lockDisassembly {Démontage antivol}
    spareDisassembly {Démontage pièce détachée}
    other {Autre}
  }`,
  '/book/summary.details.additional.value': '{ additional, select, true {oui} false {non} other {Erreur}}',
  '/book/summary.details.duration.value': '{ duration, time, short }',
  '/book/summary.details.slot.value': '{ slot, date, ::yMdHm }',
  '/book/summary.depositTerms.title': 'Modalités de dépot',

  '/book/missing-informations.pageTitle': 'Pouvez-vous nous en dire plus ?',
  '/book/missing-informations.repairLocation.title': 'Mon rendez-vous',
  '/book/missing-informations.next': 'Je passe à l\'étape suivante',
  '/book/missing-informations.rectify': 'Étape précédente',
  '/book/missing-informations.address.title': 'Mon adresse',
  '/book/missing-informations.equipmentType.title': 'Mon vélo',
  '/book/missing-informations.dirty.dismiss': 'J\'ai compris',

  '/interventions.pageTitle': 'Bienvenue sur la page de vos interventions',
  '/interventions.loading.title': 'Chargement des interventions ...',
  '/interventions.filters.title': 'Je recherche mes interventions',
  '/interventions.filters.equipmentId.label': 'Par vélo',
  '/interventions.filters.equipmentId.all': 'Tous mes vélos',
  '/interventions.filters.statusId.label': 'Par statut',
  '/interventions.filters.statusId.all': 'Tous les statuts',
  '/interventions.filters.statusId.option': `{ value, select,
    new {Nouveau}
    scheduled {Programmée}
    done {Terminée}
    returned {Facturée}
    express {Facturée en express}
    canceled {Annulée}
    closed {Clôturée}
    other {{ value }}
  }`,
  '/interventions.intervention.title': 'Mon intervention { interventionId }',
  '/interventions.noResult.title': 'Aucun résultat',
  '/interventions.noResult': 'Désolés ! Vous n\'avez pas encore d\'intervention. Pourquoi ne pas prendre rendez-vous pour la première fois ?',
  '/interventions.intervention.details': 'Tous les détails',

  '/interventions/:interventionId.pageTitle': 'Mon intervention { interventionId } en détail',
  '/interventions/:interventionId.tabs.details': 'Récapitulatif de l\'intervention',
  '/interventions/:interventionId.tabs.itvcodes': 'Récapitulatif pièces et main d\'oeuvre',
  '/interventions/:interventionId.informations.title': 'Récapitulatif de l\'intervention',
  '/interventions/:interventionId.itvcodes.title': 'Récapitulatif pièces et main d\'oeuvre',
  '/interventions/:interventionId.itvcodes.total.title': 'Récapitulatif',
  '/interventions/:interventionId.utils.title': 'Juste 1 clic pour...',
  '/interventions/:interventionId.utils.problem': 'Signaler un problème',
  '/interventions/:interventionId.utils.billing': 'Télécharger ma facture',
  '/interventions/:interventionId.utils.book': 'Reprendre un rendez-vous',
  '/interventions/:interventionId.utils.rate': 'Donner mon avis',
  '/interventions/:interventionId.utils.cancel': 'Annuler',
  '/interventions/:interventionId.dirty.retry': 'Je réessaye',
  '/interventions/:interventionId.itvcodes.item.isSav': 'Pris en charge SAV',
  '/interventions/:interventionId.itvcodes.item.quantity.label': 'Quantité',
  '/interventions/:interventionId.itvcodes.item.priceWt.label': 'Montant TTC par unité',
  '/interventions/:interventionId.itvcodes.total.totalNbParts.label': 'Quantité',
  '/interventions/:interventionId.itvcodes.total.totalWt.label': 'Total TTC',
  '/interventions/:interventionId.utils.rated': 'Votre avis : ',
  '/interventions/:interventionId.Breadcrumb.status': `{ value, select,
    new {Nouveau}
    scheduled {Programmée}
    done {Terminée}
    returned {Facturée}
    express {Facturée}
    canceled {Annulée}
    closed {Clôturée}
    other {Inconnue}
  }`,
  '/interventions/:interventionId.informations.pictures': 'Photos',

  '/interventions/:interventionId.confirmCancel.title': 'Annuler l’intervention',
  '/interventions/:interventionId.confirmCancel.disclaimer': 'Voulez vous vraiment annuler ?',
  '/interventions/:interventionId.confirmCancel.actions.cancel': 'Annuler',
  '/interventions/:interventionId.confirmCancel.actions.submit': 'Je confirme l’annulation',

  '/interventions/:interventionId/rate.pageTitle': 'Noter l\'intervention & mon atelier',
  '/interventions/:interventionId/rate.blockTitle': 'Une note entre 1 (pas content) et 5 (excellent)',
  '/interventions/:interventionId/rate.disclaimer': 'Votre intervention chez { shopName } le { datePlanned, date }', // Tout le contenu de GET interventions/details -> `informations` est dispo ici. 
  '/interventions/:interventionId/rate.comment.label': 'Votre commentaire (sera public)',
  '/interventions/:interventionId/rate.comment.placeholder': 'Merci de rester courtois',
  '/interventions/:interventionId/rate.alreadyRated': 'Vous avez déjà noté cette intervention',
  '/interventions/:interventionId/rate.submit': 'Noter',

  '/how-it-works.pageTitle': 'Comment ça marche ?',
  '/how-it-works.Steps.title': 'Les pros de Vélocenter, toujours là pour vous et votre vélo',
  '/how-it-works.Steps.step.search': 'Je recherche un réparateur',
  '/how-it-works.Steps.step.diagnosis': 'J\'explique mon besoin et je reçois un devis',
  '/how-it-works.Steps.step.rendezvous': 'Je prends rendez-vous sans rien avancer',
  '/how-it-works.Steps.step.location': 'Je me rends en atelier ou j’attends le réparateur chez moi à l’heure du rendez-vous',
  '/how-it-works.Steps.step.repaired': 'Mon vélo est prêt pour de nouvelles aventures',
  '/how-it-works.goToSearch': 'Je cherche un réparateur',
  '/how-it-works.CoveredServices.title': 'Les services Vélocenter',

  '/backoffice.jumbotron.title': 'Plus de clients, plus de vélos et moins d’administratif !',
  '/backoffice.jumbotron.content': 'Votre métier c’est les vélos. Vélocenter s’occupe du reste.',
  '/backoffice.benefits.title': 'Vélocenter, plus de temps pour votre métier à vous',
  '/backoffice.benefits.content': `
<item><icon>icons/agenda-black.svg</icon><text>+ de fluidité / Gestion d’un calendrier partagé</text></item>
<item><icon>icons/agenda-black.svg</icon><text>+ d’optimisation / Gestion de vos réservations</text></item>
<item><icon>icons/agenda-black.svg</icon><text>+ d’efficacité / Réception des réservations par téléphone possible</text></item>
<item><icon>icons/agenda-black.svg</icon><text>+ de visibilité / Notre site est votre boutique en ligne !</text></item>
  `,
  '/backoffice.howTo.title': 'Réparateur Vélocenter, moi ?',
  '/backoffice.howTo.content': `
<paragraph>Vous êtes passionné par le vélo mais vous préférez mettre les mains dans le cambouis plutôt que sur le clavier ? C’est pour vous que Vélocenter a conçu ses outils de gestion qui simplifient la vie.</paragraph>
<paragraph>Vous préférez écouter et conseiller vos clients plutôt que gérer des plannings ? Vélocenter vous fait gagner du temps pour en passer plus avec vos clients.</paragraph>
<paragraph>Comment devenir plus visible sans se ruiner en publicité ? En rejoignant le réseau des experts du vélo, vous devenez plus visible pour votre clientèle de quartier.</paragraph>
  `,
  '/backoffice.howTo.services.action': 'Les formules Vélocenter',
  '/backoffice.why.title': 'Les atouts d’un réparateur Vélocenter',
  '/backoffice.why.content': `
<item><icon>icons/agenda-black.svg</icon><text>S’engager sur un forfait transparent, pas de commissions sur les transactions.</text></item>
<item><icon>icons/agenda-black.svg</icon><text>Rejoindre une plateforme d’experts plus visible et plus compétitive (10 fois moins cher qu’une publicité Facebook).</text></item>
<item><icon>icons/agenda-black.svg</icon><text>Profiter d’un outil unique pour centraliser ses outils de gestion.</text></item>
<item><icon>icons/agenda-black.svg</icon><text>Avoir plus de temps pour son métier, plus de temps pour ses clients, plus de liberté. </text></item>
<item><icon>icons/agenda-black.svg</icon><text>Un outil pour optimiser son temps et maximiser son chiffre d’affaires.</text></item>
  `,
  '/backoffice.why.action': 'Je veux en savoir plus',
  '/backoffice.coop.title': 'Envie de rejoindre un réseau de passionné ?',
  '/backoffice.coop.content': 'Vous pouvez aussi rejoindre le réseau Vélogik Les ateliers qui regroupe les experts mordus de vélos en France.',
  '/backoffice.coop.action': 'En savoir plus sur ce réseau',

  '/services.pageTitle': 'Les formules Velocenter',
  '/services.disclaimer.text': 'A chaque expert du vélo, la formule sur-mesure adaptée à ses besoins de terrain.',
  '/services.disclaimer.button': 'Je veux en savoir plus',
  '/services.formule.basic.label': 'Pack Lancement',
  '/services.formule.premium.label': 'Pack Complet',
  '/services.formule.custom.label': 'Pack Sur-mesure',
  '/services.formule.network.label': 'Pack Réseau',
  '/services.formule.basic.desc': 'L’essentiel pour un technicien indépendant',
  '/services.formule.premium.desc': 'L’essentiel pour un atelier cycle',
  '/services.formule.custom.desc': 'Du sur-mesure pour un atelier cycle ou magasin de sport',
  '/services.formule.network.desc': 'Du sur-mesure pour un réseau d’ateliers ou de magasins',
  '/services.formule.adv1.label': 'Profil visible sur velocenter.com',
  '/services.formule.adv2.label': 'Planning des réservations et gestion des notifications et rappels de rendez-vous',
  '/services.formule.adv3.label': 'Personnalisation du profil et du planning velocenter.com',
  '/services.formule.adv4.label': 'Suivi des réparations des réparateurs',
  '/services.formule.adv5.label': 'Gestion des clients de l\'atelier',
  '/services.formule.adv6.label': 'Gestion du parc de vélos de l\'atelier',
  '/services.formule.adv7.label': 'Gestion de la facturation',
  '/services.formule.adv8.label': 'Intégration à votre logiciel de caisse',
  '/services.formule.adv9.label': 'Assistance sur-mesure',
  '/services.formule.adv10.label': 'Gestion d\'un réseau d\'ateliers de 2 à 1000 entités',
  '/services.formule.limit.label': 'Limite d\'utilisateur',
  '/services.formule.limit.value': `{ limit, select,
    1 {1 utilisateur}
    all { de 1 a * utilisateurs}
    other {{ limit } utilisateurs}
  }`,
  '/services.formule.price.value': `{ price, select,
    ask {sur demande}
    1 {{ price, number }}
    other {{ price, number, ::currency/EUR }}
  }`,
  '/services.formule.contact': 'Ça m’intéresse',
  '/services.contact.formule': `{ formule, select,
    basic {Bonjour, je souhaiterais souscrire au pack Lancement}
    premium {Bonjour, je souhaiterais souscrire au pack Complet}
    custom {Bonjour, je souhaiterais souscrire au pack Sur-mesure}
    network {Bonjour, je souhaiterais souscrire au pack Réseau}
    other {{value}}
  }`,

  'component.InterventionBlock.datePlanned.label': 'Date et heure',
  'component.InterventionBlock.datePlanned.empty': 'Pas encore planifié',
  'component.InterventionBlock.equipmentTypeValue.label': 'Mon vélo',
  'component.InterventionBlock.equipmentTypeValue.value': '{ value, select, vae {Vélo à Assistance Electrique} standard {Vélo standard} cargo {Vélo cargo} cargoae {Vélo cargo électrique} other {Type inconnu} }',
  'component.InterventionBlock.interventionId.label': 'ID de l\'intervention',
  'component.InterventionBlock.identificationCode.label': 'Identification du vélo',
  'component.InterventionBlock.statusValue.label': 'Statut',
  'component.InterventionBlock.statusValue.value': `{ value, select,
    new {Nouveau}
    scheduled {Programmée}
    done {Terminée}
    returned {Facturée}
    express {Facturée en express}
    canceled {Annulée}
    closed {Clôturée}
    other {Inconnue}
  }`,
  'component.InterventionBlock.description.label': 'Description du besoin',
  'component.InterventionBlock.shopName.label': 'Atelier / Technicien',
  'component.InterventionBlock.duration.label': 'Durée de l\'intervention',
  'component.InterventionBlock.servicesValue.label': 'Prestation de service',
  'component.InterventionBlock.servicesValue.value': `{ value, select,
    breakdown {Panne}
    overhaul {Révision ou mise en service}
    spare {Installation de pièce détachée}
    other {Autre} }`,
  'component.InterventionBlock.servicesDetailsValue.label': 'Détail de la prestation',
  'component.InterventionBlock.servicesDetailsValue.value': `{ value, select,
    speed {Mes vitesses ne passent plus}
    crankset {Mon pédalier est défectueux }
    flatTire {Mon vélo a un pneu à plat}
    brokenWheel {Ma roue est cassée}
    brakeAdjustment {Mes freins fonctionnent mal}
    brokenBrakes {Mes freins sont cassés}
    battery {Ma batterie a des soucis}
    diag {RDV pour diagnostiquer ma panne}
    otherBreakdown {Autre}
    overhaul {Je souhaite faire réviser mon vélo}
    setup {Je souhaite mettre en service un vélo neuf}
    spareMine {Je souhaite faire installer une pièce détachée en ma possession}
    spareBuy {Je souhaite faire installer une pièce détachée avec achat}
    kit {Je souhaite faire installer un kit électrique}
    accessory {Je souhaite faire installer un petit accessoire}
    idSetup {Je souhaite faire poser une identification}
    lockDisassembly {Je souhaite faire démonter un antivol}
    spareDisassembly {Je souhaite faire démonter une pièce détachée}
    other {Autre} }`,
  'component.InterventionBlock.repairLocationValue.label': 'Type de prestation',
  'component.InterventionBlock.repairLocationValue.value': '{ value, select, shop {En atelier} home {A domicile} other {Inconnue} }',
  'component.InterventionBlock.additional.label': 'Révision complémentaire',
  'component.InterventionBlock.additional.value': '{ value, select, true {Oui} false {Non} other {Erreur} }',
  'component.InterventionBlock.addressFull.label': 'Adresse',

  'error.ws.technical': 'Oups ! Une erreur technique est survenue, veuillez réessayer ultérieurement !',
  'error.ws.unknown': 'Erreur inconnu',
  'error.ws.unknownUser': 'Utilisateur inconnu',
  'error.ws.customNameIsMissing': 'Oups ! Il nous manque le nom personnalisé, pouvez-vous l\'ajouter ?',
  'error.ws.equipmentTypeIsMissing': 'Oups ! Il nous manque le type d\'équipement, pouvez-vous l\'ajouter ?',
  'error.ws.identificationSystemIsMissing': 'Oups ! Il nous manque le choix du type d\'identification, pouvez-vous l\'ajouter ?',
  'error.ws.brandIsMissing': 'Oups ! Il nous manque la marque de votre vélo, pouvez-vous l\'ajouter ?',
  'error.ws.modelIsMissing': 'Oups ! Il nous manque le modèle de votre vélo, pouvez-vous l\'ajouter ?',
  'error.ws.unknownIntervention': 'Oups ! Vous ne pouvez pas accéder à cette intervention !',
  'error.ws.repairLocationIsMissing': 'Oups ! Il nous manque le choix du type de rendez-vous, pouvez-vous l\'ajouter?',
  'error.ws.latIsMissing': 'Oups ! Il nous manque une adresse, pouvez-vous l\'ajouter?',
  'error.ws.repairServiceDetailsIsEmpty': 'Oups ! Il nous manque plus de détails pour votre besoin, pouvez-vous l\'ajouter?',
  'error.ws.descriptionIsEmpty': 'Oups ! Pouvez-vous donner plus de détails quant à votre besoin ?',
  'error.ws.customNameIsEmpty': 'Oups ! Il nous manque le nom personnalisé de votre vélo, pouvez-vous l\'ajouter ?',
  'error.ws.lastNameIsEmpty': 'Oups ! Il nous manque votre nom, pouvez-vous l\'ajouter ?',
  'error.ws.firstNameIsEmpty': 'Oups ! Il nous manque votre prénom, pouvez-vous l\'ajouter ?',
  'error.ws.descriptionIsMissing': 'Oups ! Il nous manque le message ou la description, pouvez-vous l\'ajouter ?',
  'error.ws.emailIsEmpty': 'Oups ! Il nous manque l\'email, pouvez-vous l\'ajouter ?',
  'error.ws.passwordTooShort': 'Oups ! Le mot de passe est trop court, pouvez-vous mettre au moins 10 caractères ?',
  'error.ws.wrongEmailOrPassword': 'Oups ! Mauvais email ou mot de passe !',
  'error.ws.passwordIsEmpty': 'Oups ! Le mot de passe est manquant !',
  'error.ws.firstNameIsMissing': 'Oups ! Le prénom est manquant !',
  'error.ws.lastNameIsMissing': 'Oups ! Le nom est manquant !',
  'error.ws.unexpected': 'Oups ! Une erreur inattendue est survenue, veuillez réessayer ultérieurement !',
  'error.ws.streetIsMissing': 'Oups ! Vous devez préciser votre adresse exacte : n°, rue, code postal et ville',
  'error.ws.wrongPreflightSelectionForShopForTypeRepairLocation': 'Cet atelier ne propose malheureusement pas ce type de prestation pour le lieux donné !',
  'error.ws.streetIsEmpty': 'Oups ! Votre adresse précise est nécessaire pour prendre rendez-vous à votre domicile !',
  'error.ws.wrongPreflightSelectionForShopForTypeEquipmentType': 'Oups ! Cet atelier ne propose malheureusement pas ce type de prestation !',
  'error.ws.repairLocationNotAllowedOnline': 'Merci de contacter l\'atelier au {phoneNumber} pour prendre rendez-vous pour ce type de prestation !',
  'error.ws.daySlotDelayIsMissing': 'Oups ! Cet atelier n\'a pas assez de temps pour traiter votre demande. Veuillez réessayer !',
  'error.ws.basicCannotRepareProEquipment': 'Oups ! Cet atelier n\'a pas les compétences pour réparer votre vélo',
  'error.ws.duplicateEquipmentIdentifierFound': 'Cette équipement apparait de multiple fois. Nous ne gerons pas ce cas de figure actuellement. Veuillez utiliser l\'ajout "Grand public"',
  'error.ws.wrongEquipmentIdentifier': 'L\'identifiant de l\'équipement est introuvable.',
  'error.ws.noNotificationGroup': 'Erreur technique : noNotificationGroup',
  'error.ws.noNotifications': 'Erreur technique : noNotifications',
  'error.ws.repairServiceIsMissing': 'Veuillez préciser votre problème',
  'error.ws.invalidOrExpiredSlot': 'Le créneau que vous avez choisi n\'est plus disponible. Veuillez en sélectionner un autre à l\'étape précédente.',
  'error.ws.invalidPhoneFormat': 'Le format du numéro de téléphone est invalide.',
  'error.ws.cannotSendToThisNumber': 'Impossible d\'envoyer de code à ce numero',
  'error.ws.pleaseWaitForNewCode': 'Veuillez patientez avant de recevoir un nouveau code',
  'error.ws.phoneAlreadyValidated': 'Ce numero de téléphone est déjà validé',
  'error.ws.wrongCode': 'Code invalide',
  'error.ws.invalidPhoneNumber': 'Le numéro de téléphone est invalide.',
  'error.ws.brandIsEmpty': 'Oups ! Il nous manque la marque de votre vélo, pouvez-vous l\'ajouter ?',

  'error.ws.cannotCancelIntervention': 'Impossibe d\'annuler l\'intervention',

  'error.front.mismatchPasswords': 'Oups ! Les 2 nouveaux mots de passe ne correspondent pas !',
  'error.front.passwordTooShort': 'Oups ! Le format du nouveau mot de passe est trop court !',
  'error.front.wrongCurrentPassword': 'Oups ! Le mot de passe actuel ne correspond pas !',
  'error.front.badInterventionService': 'Oups ! Il nous manque votre besoin, pouvez-vous l\'ajouter ?',
  'error.front.noSelectedSlot': 'Oups ! Il nous manque un créneau de rendez-vous, pouvez-vous le sélectionner ?',
  'error.front.missingMandatoryFilter': 'Oups ! Il nous manque une information essentielle pour pouvoir rechercher !',
  'error.front.missingEquipmentType': 'Veuillez selectionner un type de vélo avant de passer à la suite',

  'error.component.title': 'Erreur fatale',
  'error.component.content': 'Je crois bien que vous avez trouvé un bug ! Ne vous inquiétez pas, Velocenter a automatiquement généré un rapport d\'erreur qui devrait nous arriver prochainement.',
  'error.component.traceSent': 'Le rapport d\'erreur a bien été reçu. Nous allons corriger votre problème au plus vite !',

  //TODO: i don't know where & components
  '@velogik/component-confirm-transition.title': 'component-confirm-transition.title',
  '@velogik/component-confirm-transition.disclaimer': 'component-confirm-transition.disclaimer',
  '@velogik/component-confirm-transition.actions.stay': 'component-confirm-transition.actions.stay',
  '@velogik/component-confirm-transition.actions.change': 'component-confirm-transition.actions.change',
}
