import { createContext, useContext, useRef, } from 'react'

export const AnalyticsContext = createContext()

export function useAnalytics () { return useContext(AnalyticsContext) }

export function AnalyticsProvider ({ children }) {
  const hasAnalytics = useRef(false)

  if (!window.dataLayer) {
    window.dataLayer = []
  }

  function insertGTM (GTM_ID) {
    if (GTM_ID) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'gtm.start': new Date().getTime(), event:'gtm.js' })

      const f = document.getElementsByTagName('script')[0]
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://www.googletagmanager.com/gtm.js?id=' + GTM_ID

      f.parentNode.insertBefore(script,f)
      hasAnalytics.current = true
    }
  }

  function push(data) {
    if (hasAnalytics.current) {
      window.dataLayer.push(data)
    }
  }

  function webVitals ({ id, name, value }) {
    push({ event: 'coreWebVitals', webVitalsMeasurement: { id, name, value, } })
  }

  return (
    <AnalyticsContext.Provider value={{ insertGTM, push, webVitals, }}>
      {children}
    </AnalyticsContext.Provider>
  )
}
