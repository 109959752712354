import { useEffect, } from 'react'

import { useHistory, } from 'react-router-dom'

export function Analytics () {
  const history = useHistory()

  useEffect(() => {
    // TODO: page init
    const unlisten = history.listen((/* action */) => {
      // TODO: page changed
    })

    return () => unlisten()
  }, [history])

  return null
}
