import { useReducer, useEffect, } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'

import { useApp, } from '@velogik/component-app'
import { Button, } from '@velogik/component-button'
import { useIntl, } from '@velogik/component-intl'
import { Url, } from '@velogik/component-link'
import { join, } from '@velogik/helper-classnames'

import style from './Header.module.scss'

function drawerReducer (state, action) { return action == null ? !state : action }

export function Header () {
  const { appState, signout, } = useApp()
  const { formatMessage } = useIntl()
  const history = useHistory()

  const links = [
    { id: 'book', to: '/search-shops' },
  ].concat( appState.user
    ? [
      { id: 'equipments', to: '/equipments' },
      { id: 'interventions', to: '/interventions' },
    ]
    : appState.brandSlug
      ? appState.constants.whitelabel.headers
      : [
        { id: 'pro', to: '/pro' },
        { id: 'backoffice', to: '/backoffice' },
      ]
  ).filter(_ => _)

  const [drawer, toggleDrawer] = useReducer(drawerReducer, false)

  useEffect(() => { document.body.style.overflow = drawer ? 'hidden' : null }, [drawer])
  useEffect(() => () => { document.body.style.overflow = null }, [])

  useEffect(() => {
    toggleDrawer(false)
    const unlisten = history.listen(() => toggleDrawer(false))

    return () => unlisten()
  }, [history])

  return (
    <nav className={join(style.root, drawer && style.open)}>
      <div className={style.dropdown}>
        <div className={style.header}>
          <img
            src={`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/root/logo`}
            alt={formatMessage({ id: 'header.title' })} className={style.logo} />
        </div>
        <div className={style.content}>
          {appState.user && <NavLink className={style.item} to="/account">
            {formatMessage({ id: 'header.account' })}
          </NavLink>}

          {links.map(({id, label, url, className, ...props}) => (
            url
              ? (
                <Url
                  key={url}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={join(style.item, className)}
                  {...props}
                >
                  {label}
                </Url>
              ) : (
                <NavLink key={id}
                  to={`/${id}`}
                  className={isActive => join(style.item, className, isActive && style.active)}
                  {...props}>
                  {formatMessage({ id: `header.${id}` })}
                </NavLink>
              )
          ))}

          {!appState.user && <Button className={style.item} tag={Link} to="/sign-in" dark outlined>
            {formatMessage({ id: 'header.sign-in' })}
          </Button>}
          {!appState.user && <Button className={style.item} tag={Link} to="/sign-up">
            {formatMessage({ id: 'header.sign-up' })}
          </Button>}
          {appState.user && <Button className={style.item} onClick={signout} type="button" outlined dark>
            {formatMessage({ id: 'header.signout' })}
          </Button>}
        </div>
      </div>

      <div className={style.navbar}>
        <Link className={style.logo} to={appState.brandSlug ? '#' : '/'}>
          <img
            src={`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/root/logo`}
            alt={formatMessage({ id: 'header.title' })} />
        </Link>

        {links.map(({id, label, url, className, ...props}) =>
          url
            ? (
              <Button
                key={url}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                tag={Url}
                className={join(style.item, className)}
                text dark
                {...props}
              >
                {label}
              </Button>
            ) : (
              <Button
                key={id}
                to={`/${id}`}
                tag={NavLink}
                className={isActive => join(style.item, className, isActive && style.active)}
                text dark
                {...props}
              >
                {formatMessage({ id: `header.${id}` })}
              </Button>
            )
        )}

        <span className={style.spacer} />

        {!appState.user && <Button className={style.item} tag={Link} to="/sign-in" dark outlined>
          {formatMessage({ id: 'header.sign-in' })}
        </Button>}
        {!appState.user && <Button className={style.item} tag={Link} to="/sign-up">
          {formatMessage({ id: 'header.sign-up' })}
        </Button>}
        {appState.user && <Button className={style.item} onClick={signout} type="button" text>
          {formatMessage({ id: 'header.signout' })}
        </Button>}
        {appState.user && <Button className={style.item} tag={NavLink} to="/account">
          {formatMessage({ id: 'header.account' })}
        </Button>}

        <button className={style.drawerToggle} onClick={() => toggleDrawer()} type="button">
          <span className={style.text}>{formatMessage({ id: 'header.burger' })}</span>
          <div className={style.burger} />
        </button>
      </div>
    </nav>
  )
}
