import { useState, } from 'react'
import { ApiProvider, } from 'react-rest-api'

import { AppProvider, APP_STATUS, } from '@velogik/component-app'
import { IntlProvider, useFormatError, ERROR_TYPE } from '@velogik/component-intl'
import { NotificationsProvider, useNotify } from '@velogik/component-notifications'
import { removeUndefineds } from '@velogik/helper-object'

import { Main } from 'Main'

import './App.scss'
import './theme.scss'

import { messages } from './fr.js'

function AppWrapper () {
  const { notify, } = useNotify()
  const formatError = useFormatError()
  const [apiUrl] = useState(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`)
  const [apiConfig, setApiConfig] = useState({
    headers: removeUndefineds({
      'Content-Type': 'application/json',
      'X-Velocenter-Apikey': process.env.REACT_APP_API_KEY,
      Authorization: sessionStorage.getItem('userToken') || localStorage.getItem('userToken') || undefined
    })
  })

  function signin (userToken, storageType = 'session') {
    window[`${storageType}Storage`].setItem('userToken', userToken)
    setApiConfig({
      headers: {
        'Content-Type': 'application/json',
        'X-Velocenter-Apikey': process.env.REACT_APP_API_KEY,
        Authorization: userToken
      }
    })
  }

  function signout () {
    localStorage.removeItem('userToken')
    sessionStorage.removeItem('userToken')
    setApiConfig({
      headers: {
        'Content-Type': 'application/json',
        'X-Velocenter-Apikey': process.env.REACT_APP_API_KEY
      }
    })
  }

  function resolveHook (response) {
    return (response.ok ? response.json() : response.json().then(res => Promise.reject(res)))
      .then(response => response && response.code ? Promise.reject(response) : Promise.resolve(response))
  }

  function rejectHook (response) {
    if (!response || !response.code) {
      notify(formatError({ code: 'networkError', prefix: ERROR_TYPE.FRONT }))
    } else if (response.code === 'invalidOrExpiredToken') {
      notify(formatError(response))
      signout()
    }
    return Promise.reject(response || { code: 'unexpected' })
  }

  return (
    <ApiProvider
      url={apiUrl}
      config={apiConfig}
      resolveHook={resolveHook}
      rejectHook={rejectHook}>
      <AppProvider
        reducer={appReducer}
        initialState={appInitialState}
        initializer={appInitializer}
        signin={signin} signout={signout}>
        <Main />
      </AppProvider>
    </ApiProvider>
  )
}

export function App() {
  return (
    <IntlProvider locale="fr" defaultLocale="fr"
      messages={messages}
      onError={onIntlError}>
      <NotificationsProvider>
        <AppWrapper />
      </NotificationsProvider>
    </IntlProvider>
  )
}

function appReducer (state, action) {
  switch (action.type) {
    case 'ready': return {
      ...state,
      status: APP_STATUS.LOADED,
      ...action.payload,
    }
    case 'error': return {
      ...state,
      status: APP_STATUS.ERROR,
      error: action.payload
    }
    case 'updateUserInfos':
      return {
        ...state,
        user: action.payload,
      }
    default: return state
  }
}

const appInitialState = {
  status: APP_STATUS.LOADING,
  user: undefined,
  brandSlug: getBrandSlug(),
}

function appInitializer (state) {
  return state
}

function onIntlError (err) {
  if (process.env.REACT_APP_IGNORE_TR_ERROR) {
    switch (err.code) {
      case 'MISSING_TRANSLATION':
        console.info(err.code, err.message)
        break
      default:
        console.error(err.code, err.message)
    }
    return
  }

  if (err.code === 'MISSING_TRANSLATION') {
    console.warn('Missing translation', err.message)
    return
  }
  throw err
}

function getBrandSlug () {
  const host = window.location.hostname
  const legalDomains = (process.env.REACT_APP_LEGAL_DOMAINS || '')
    .split(' ')
    .filter(_ => _)

  const isWhitelisted = legalDomains.includes(host)

  return isWhitelisted ? undefined : host
}
