import { useEffect, } from 'react'

import { useLocation, } from 'react-router-dom'

export function RouteEvents () {
  // const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // useEffect(() => {
  //   return history.listen((action) => {
  //     console.log('history.listen action', action)
  //   })
  // }, [history])

  return null
}
